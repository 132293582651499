<template>
  <!-- 线条样式 -->
  <div class="menu-content-border-box" id="menu-content-line-style-box">
    <div class="menu-use-content-inside" ref="lineStyleBox">
      <ul v-if="borderAccout.common" class="menu-use-box">
        <li
          class="menu-border-iconli"
          v-for="(item, index) in borderStyleList"
          :key="index"
          @click="getLayoutType(item.borderStyle,mapEditDarkMode?item.imgWhite:item.borderIcon)"
        >
          <img v-if="mapEditDarkMode" class="menu-border-icon" :src="item.imgWhite" />
          <img v-else class="menu-border-icon" :src="item.borderIcon" />
        </li>
      </ul>
      <ul v-if="borderAccout.Straight" class="menu-use-box">
        <li
          class="menu-border-iconli"
          v-for="(item, index) in borderStyleStraightLine"
          :key="index"
          @click="getLayoutType(item.borderStyle,mapEditDarkMode?item.imgWhite:item.borderIcon)"
        >
          <img v-if="mapEditDarkMode" class="menu-border-icon" :src="item.imgWhite" />
          <img v-else class="menu-border-icon" :src="item.borderIcon" />
        </li>
      </ul>
      <ul v-if="borderAccout.Curve" class="menu-use-box">
        <li
          class="menu-border-iconli"
          v-for="(item, index) in borderStyleTimeLine"
          :key="index"
          @click="getLayoutType(item.borderStyle,mapEditDarkMode?item.imgWhite:item.borderIcon)"
        >
          <img v-if="mapEditDarkMode" class="menu-border-icon" :src="item.imgWhite" />
          <img v-else class="menu-border-icon" :src="item.borderIcon" />
        </li>
      </ul>
      <ul v-if="borderAccout.DoubelStraight" class="menu-use-box">
        <li
          class="menu-border-iconli"
          v-for="(item, index) in borderStyleDoubelStraight"
          :key="index"
          @click="getLayoutType(item.borderStyle,mapEditDarkMode?item.imgWhite:item.borderIcon)"
        >
          <img v-if="mapEditDarkMode" class="menu-border-icon" :src="item.imgWhite" />
          <img v-else class="menu-border-icon" :src="item.borderIcon" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapMutations,mapState } from "vuex";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import MindOperateUIControllerView from "../tools/MindOperateUIControllerView";
import MindType from "../../../viewmodel/datatype/MindType";
export default {
  name: "ComplieChildMenuLayout",
  data() {
    return {
      borderStyleList: [
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straightLineWhite.svg"),
          borderStyle: MindElementType.STRAIGHT_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line2_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straight_line2_icon.svg"),
          borderStyle: MindElementType.STRAIGHT_LINE_2,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/curve_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/curve_line_icon.svg"),
          borderStyle: MindElementType.CURVE_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_curve_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straight_curve_line_icon.svg"),
          borderStyle: MindElementType.STRAIGHT_CURVE_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon_124.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straight_line_icon_124.svg"),
          borderStyle: MindElementType.CURVE_LINE_CIRCULAR,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/curve_line_2_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/curve_line_2_icon.svg"),
          borderStyle: MindElementType.CURVE_LINE_2,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon_123.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straight_line_icon_123.svg"),
          borderStyle: MindElementType.CURVE_LINE_AVERAGE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/right_angle_corner_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/right_angle_corner_line_icon.svg"),
          borderStyle: MindElementType.RIGHT_ANGLE_CORNER_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/right_angle_corner_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/right_angle_corner_icon.svg"),
          borderStyle: MindElementType.RIGHT_ANGLE_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/full_right_angle_corner_line.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/full_right_angle_corner_line.svg"),
          borderStyle: MindElementType.FULL_RIGHT_ANGLE_CORNER_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/right_angle_corner_arrow_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/right_angle_corner_arrow_line_icon.svg"),
          borderStyle: MindElementType.RIGHT_ANGLE_CORNER_ARROW_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/full_right_angle_corner_arrow_line.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/full_right_angle_corner_arrow_line.svg"),
          borderStyle: MindElementType.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE,
        },
      ],
      borderStyleStraightLine: [
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straightLineWhite.svg"),
          borderStyle: MindElementType.STRAIGHT_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/curve_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/curve_line_icon.svg"),
          borderStyle: MindElementType.CURVE_LINE,
        },
      ],
      borderStyleTimeLine: [
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/time_line_circular.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/darkModeIcon/time_line_circular.svg"),
          borderStyle: MindElementType.TIME_LINE_CIRCULAR,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/time_line_diamond.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/darkModeIcon/time_line_diamond.svg"),
          borderStyle: MindElementType.TIME_LINE_DIAMOND,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/time_line_circular_connect_head.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/darkModeIcon/time_line_circular_connect_head.svg"),
          borderStyle: MindElementType.TIME_LINE_CIRCULAR_CONNECT_HEAD,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/time_line_circular_connect_head_title.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/darkModeIcon/time_line_circular_connect_head_title.svg"),
          borderStyle: MindElementType.TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/time_line_circular_connect_head_order.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/darkModeIcon/time_line_circular_connect_head_order.svg"),
          borderStyle: MindElementType.TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/time_line_circular_order.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/darkModeIcon/time_line_circular_order.svg"),
          borderStyle: MindElementType.TIME_LINE_CIRCULAR_ORDER,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/time_line_circular_arrow_head.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/darkModeIcon/time_line_circular_arrow_head.svg"),
          borderStyle: MindElementType.TIME_LINE_CIRCULAR_ARROW_HEAD,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/time_line_circular_order_1.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/darkModeIcon/time_line_circular_order_1.svg"),
          borderStyle: MindElementType.TIME_LINE_CIRCULAR_ORDER_1,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/time_line_circular_hexagon_order.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time_line/darkModeIcon/time_line_circular_hexagon_order.svg"),
          borderStyle: MindElementType.TIME_LINE_HEXAGON_ORDER,
        },
      ],
      borderStyleDoubelStraight: [
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/straight_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/straightLineWhite.svg"),
          borderStyle: MindElementType.STRAIGHT_LINE,
        },
        {
          borderIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/lightModeIcon/curve_line_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/line_layout/darkModeIcon/curve_line_icon.svg"),
          borderStyle: MindElementType.CURVE_LINE,
        },
      ],
      borderStyleCurveLine: [],
      layoutType: "",
      viewModel: null,
      mindOperateUIControllerView: null,
      borderAccout: {
        common: false,
        Straight: false,
        Curve: false,
        DoubelStraight:false
      },
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => state.mapEditIsDardarkMode.darkMode,//深色模式
    }),
  },
  created() {},
  mounted() {
    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
      this.getBorderAccout(this.$store.state.initData);
    }
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations(["lineStyleLayout"]),
    getBorderAccout(newInitData) {
      let borderAccoutData = newInitData;
      if (borderAccoutData.mindType == MindType.BUBBLE_MAP) {
        this.borderAccout.Straight = true;
        this.borderAccout.common = false;
        this.borderAccout.Curve = false;
        this.borderAccout.DoubelStraight = false;
      } else if (borderAccoutData.mindType == MindType.LINE_MAP) {
        this.borderAccout.Straight = false;
        this.borderAccout.common = true;
        this.borderAccout.Curve = false;
        this.borderAccout.DoubelStraight = false;
      } else if (borderAccoutData.mindType == MindType.TIME_MAP) {
        this.borderAccout.Straight = false;
        this.borderAccout.common = false;
        this.borderAccout.Curve = true;
        this.borderAccout.DoubelStraight = false;
      } else if (borderAccoutData.mindType == MindType.DOUBLE_BUBBLE_MAP) {
        this.borderAccout.Straight = false;
        this.borderAccout.common = false;
        this.borderAccout.Curve = false;
        this.borderAccout.DoubelStraight = true;
      }
    },
    getLayoutType(type,url) {
      if (this.mindOperateUIControllerView == null) {
        this.mindOperateUIControllerView = new MindOperateUIControllerView(
          this.viewModel,
          this.viewModel
        );
      }
      this.mindOperateUIControllerView.onSelected(type);
      this.lineStyleLayout(url)
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.lineStyleBox;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (dardarkMode.darkMode) {
            // boxDom.style.color = dardarkMode.fontColor;
            boxDom.style.background = dardarkMode.bgColor;
            boxDom.style.border = '1px solid rgba(255,255,255,.3)';
          } else {
            // boxDom.style.color = "#666666";
            boxDom.style.background = "#fff";
            boxDom.style.border = 0;
          }
        }
      });
    },
  },
  watch: {
    "$store.state.initData"(newInitData, olInitData) {
      this.getBorderAccout(newInitData);
    },
    "$store.state.editMindmap"(newval, olval) {
      this.viewModel = newval;
    },
    //新手引导设置线条样式 
    "$store.state.setBorderlLineStyle"(newval, olval) {
      if(newval){
        let item = this.borderStyleList[6];
        this.getLayoutType(item.borderStyle,item.borderIcon)
      };
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.menu-use-content-inside {
  width: 268px;
  height: 184px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
}
.menu-use-content-inside ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.menu-use-content-inside ul li {
  width: 45px;
  height: 45px;
  float: left;
  list-style: none;
  margin: 8px 8px;
  padding: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.menu-use-content-inside ul li:hover {
  background: var(--rightClickMenuBg);
}
.menu-use-content-inside ul li img {
  /* width: 80%;
  height: 80%; */
}
.menu-theme-border {
  background: #e0e0e0;
}
</style>