<template>
  <section v-if="spinning" class="association-map-contre">
    <div class="loading-box">
      <a-spin
        class="loading-child"
        tip="Loading..."
        :spinning="spinning"
        :delay="500"
      >
      </a-spin>
    </div>
  </section>
  <div v-else class="association-map-model">
    <div v-if="isMyMap" class="association-map-contre">
      <!-- 最近创作 -->
      <div
        v-if="isArchiveOrRecent === true"
        class="association-map-contre-title"
      >
        <div class="association-map-h3">
          <span
            @click="switchArchiveOrRecent(true)"
            class="association-map-recent-creation association-map-select"
            >{{ getString(strings.My_map) }}</span
          >
          <span
            @click="switchArchiveOrRecent(false)"
            class="association-map-induce"
            >{{ getString(strings.Mind_Archive) }}</span
          >
          <span
            @click="switchArchiveOrRecent('currentPaging')"
            class="association-map-induce"
          >
            {{ getString(strings.Current_Paging) }}
          </span>
          <img
            @click="colseAssocation"
            class="close-button-img"
            src="../../../../assets/img/popupsImg/colse_new.svg"
          />
        </div>
        <div class="association-map-search">
          <div class="left-search">
            <a-button
              class="search-button"
              shape="circle"
              icon="search"
              size="small"
              @click="searchButton()"
            />
            <input
              class="search-box"
              type="text"
              v-model="searchMapText"
              placeholder="请输入最近创作的导图名称或文件夹"
              v-on:keyup.enter="searchButton()"
              @focus="useShortcut = false"
              @blur="useShortcut = true"
            />
            <!-- <span  :placeholder="$getStringObj.getString($Strings.Search_Pagination)"
                        class="cleariconfont clear-search-text-icon"
                        v-if="searchText != ''"
                        @click="clearSearchText()"
                        >&#xe605;</span
                        > -->
          </div>
        </div>
        <div v-if="crumbsNav.length > 0" class="crumbs-nav-or-level">
          <div class="ret-lecel" @click="levelClick">
            <img
              class="mind-file-left-img-icon"
              src="../../../../assets/img/groupImg/back-father-file.png"
              alt="返回"
            />
            上一级
          </div>
          <div class="crumbs-nav">
            <div
              class="nav-mind-load"
              v-for="(item, index) in crumbsNav"
              :key="index"
              @click="parentFile(item, index)"
            >
              <template v-if="index != crumbsNav.length - 1">
                <div class="nav-load-name nav-load-text">
                  {{ item.name }}
                </div>
                <img
                  class="mind-load-img"
                  style="margin-top: -16px"
                  src="../../../../assets/img/myMindIcon/right-arrow.svg"
                  alt=""
                />
              </template>
              <template v-else>
                <div class="nav-load-name mind-load-last-text">
                  {{ item.name }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="my-map-list">
          <template v-if="recentMapList && recentMapList.length > 0">
            <div v-for="(item, index) in recentMapList" :key="index">
              <div
                v-if="item.isDir == false"
                class="my-map-list-box"
                @click="isTherePaging(item)"
              >
                <img
                  class="my-mind-img"
                  src="../../../../assets/img/user/plate_plan_4.jpg"
                  v-real-img="checkImgSrc(item.cover)"
                  alt=""
                />
                <div class="my-mind-list-text" style="margin: 0 20px 0 15px">
                  <div
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="my-mind-main-subheading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ item.subtitle }}
                  </div>
                </div>
                <div class="my-mind-time">
                  {{ timeConversion(item.createTime) }}
                </div>
              </div>
              <div
                v-else
                class="my-map-list-box"
                @click.stop="showMapcaseBox(item)"
              >
                <span
                  v-if="item.colorCode == 0 || item.colorCode == ''"
                  class="fileiconfont my-mind-icon file-color-blue"
                  style="color: #6bb7da"
                  >&#xe761;</span
                >
                <span
                  v-if="item.colorCode == 1"
                  class="fileiconfont my-mind-icon file-color-yellow"
                  >&#xe761;</span
                >
                <span
                  v-if="item.colorCode == 2"
                  class="fileiconfont my-mind-icon file-color-red"
                  >&#xe761;</span
                >
                <span
                  v-if="item.colorCode == 3"
                  class="fileiconfont my-mind-icon file-color-green"
                  >&#xe761;</span
                >
                <span
                  v-if="item.colorCode == 4"
                  class="fileiconfont my-mind-icon file-color-gray"
                  >&#xe761;</span
                >
                <div class="my-mind-list-text" style="margin: 0 20px 0 15px">
                  <div
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="my-mind-main-subheading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ item.subtitle }}
                  </div>
                </div>
                <div class="my-mind-time">
                  {{ timeConversion(item.createTime) }}
                </div>
              </div>
            </div>
          </template>
          <div class="current-location" v-else>
            {{
              getString(
                loadingText ? strings.Loading : strings.Mind_Group_No_Content
              )
            }}
          </div>
        </div>
      </div>
      <!-- 当前分页 -->
      <div
        v-else-if="isArchiveOrRecent == 'currentPaging'"
        class="association-map-contre-title"
      >
        <div class="association-map-h3">
          <span
            @click="switchArchiveOrRecent(true)"
            class="association-map-recent-creation"
            >{{ getString(strings.My_map) }}</span
          >
          <span
            @click="switchArchiveOrRecent(false)"
            class="association-map-induce"
            >{{ getString(strings.Mind_Archive) }}</span
          >
          <span
            @click="switchArchiveOrRecent('currentPaging')"
            class="association-map-induce association-map-select"
          >
            {{ getString(strings.Current_Paging) }}
          </span>
          <img
            @click="colseAssocation"
            class="close-button-img"
            src="../../../../assets/img/popupsImg/colse_new.svg"
          />
        </div>
        <div class="association-map-search">
          <div class="left-search">
            <a-button
              class="search-button"
              shape="circle"
              icon="search"
              size="small"
              @click="searchCurrentPagingBtn()"
            />
            <input
              class="search-box"
              type="text"
              v-model="currentPagingText"
              :placeholder="getString(strings.Search_Pagination)"
              v-on:keyup.enter="searchCurrentPagingBtn()"
              @focus="useShortcut = false"
              @blur="useShortcut = true"
            />
          </div>
        </div>
        <div class="my-map-list" ref="currentPagingBox">
          <template v-if="currentPagingList && currentPagingList.length > 0">
            <div
              v-for="(item, index) in currentPagingList"
              :key="index"
              class="width-100-percent"
            >
              <div class="my-map-list-box" @click="openMind(item)">
                <img
                  class="my-mind-img"
                  src="../../../../assets/img/user/plate_plan_4.jpg"
                  v-real-img="checkImgSrc(item.cover)"
                  alt=""
                />
                <div class="my-mind-list-text" style="margin: 0 20px 0 15px">
                  <div
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="current-location" v-else>
            {{
              getString(
                loadingText ? strings.Loading : strings.Mind_Group_No_Content
              )
            }}
          </div>
        </div>
      </div>
      <!-- 导图分页 -->
      <div
        v-else-if="isArchiveOrRecent == 'MappingPaging'"
        class="association-map-contre-title"
      >
        <div class="association-map-h3 text-left">
          <!-- 选择分页 -->
          <span class="select-paging"
            >{{ getString(strings.Select_Paging) }}（{{
              currentPagingList.length
            }}）</span
          >
          <img
            @click="colseMappingPaging"
            class="close-button-img"
            src="../../../../assets/img/popupsImg/colse_new.svg"
          />
        </div>
        <div class="association-map-search">
          <div class="left-search">
            <a-button
              class="search-button"
              shape="circle"
              icon="search"
              size="small"
              @click="searchMappingPagingBtn()"
            />
            <input
              class="search-box"
              type="text"
              v-model="currentPagingText"
              :placeholder="getString(strings.Search_Pagination)"
              v-on:keyup.enter="searchMappingPagingBtn()"
              @focus="useShortcut = false"
              @blur="useShortcut = true"
            />
          </div>
        </div>
        <div class="my-map-list" ref="mappingPagingBox">
          <template v-if="currentPagingList && currentPagingList.length > 0">
            <div
              v-for="(item, index) in currentPagingList"
              :key="index"
              class="width-100-percent"
            >
              <div class="my-map-list-box" @click="openMind(item)">
                <img
                  class="my-mind-img"
                  src="../../../../assets/img/user/plate_plan_4.jpg"
                  v-real-img="checkImgSrc(item.cover)"
                  alt=""
                />
                <div class="my-mind-list-text" style="margin: 0 20px 0 15px">
                  <div
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="current-location" v-else>
            {{
              getString(
                loadingText ? strings.Loading : strings.Mind_Group_No_Content
              )
            }}
          </div>
        </div>
      </div>
      <!-- 归档 -->
      <div
        v-else-if="isArchiveOrRecent === false"
        class="association-map-contre-title"
      >
        <div class="association-map-h3">
          <span
            @click="switchArchiveOrRecent(true)"
            class="association-map-recent-creation"
            >{{ getString(strings.My_map) }}</span
          >
          <span
            @click="switchArchiveOrRecent(false)"
            class="association-map-induce association-map-select"
            >{{ getString(strings.Mind_Archive) }}</span
          >
          <!-- 当前分页 -->
          <span
            @click="switchArchiveOrRecent('currentPaging')"
            class="association-map-induce"
          >
            {{ getString(strings.Current_Paging) }}
          </span>
          <!-- <img @click="colseAssocation" style="width: 18px;float: right;cursor: pointer;" src="../../../../assets/img/myMindIcon/close.svg"/> -->
          <img
            @click="colseAssocation"
            class="close-button-img"
            src="../../../../assets/img/popupsImg/colse_new.svg"
          />
        </div>
        <div class="association-map-search">
          <div class="left-search">
            <a-button
              class="search-button"
              shape="circle"
              icon="search"
              size="small"
              @click="searchButtonArchive()"
            />
            <input
              class="search-box"
              type="text"
              v-model="searchArchiveText"
              placeholder="请输入归纳的书本名称或书夹名"
              v-on:keyup.enter="searchButtonArchive()"
              @focus="useShortcut = false"
              @blur="useShortcut = true"
            />
            <!-- <span
                        class="cleariconfont clear-search-text-icon"
                        v-if="searchText != ''"
                        @click="clearSearchText()"
                        >&#xe605;</span
                        > -->
          </div>
        </div>
        <div v-if="crumbsNav.length > 0" class="crumbs-nav-or-level">
          <div class="ret-lecel" @click="levelClick">
            <img
              class="mind-file-left-img-icon"
              src="../../../../assets/img/groupImg/back-father-file.png"
              alt="返回"
            />
            上一级
          </div>
          <div class="crumbs-nav">
            <div
              class="nav-mind-load"
              v-for="(item, index) in crumbsNav"
              :key="index"
              @click="parentFile(item, index)"
            >
              <template v-if="index != crumbsNav.length - 1">
                <div class="nav-load-name nav-load-text">
                  {{ item.name }}
                </div>
                <img
                  class="mind-load-img"
                  style="margin-top: -16px"
                  src="../../../../assets/img/myMindIcon/right-arrow.svg"
                  alt=""
                />
              </template>
              <template v-else>
                <div class="nav-load-name mind-load-last-text">
                  {{ item.name }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="my-map-list padding-left-5" id="MyMapList">
          <template v-if="fileMapList && fileMapList.length > 0">
            <div
              v-for="(itemArchive, index) in fileMapList"
              :key="index"
              class="book-select"
            >
              <div
                class="bookcase-item"
                @click.stop="showBookcaseBox(itemArchive)"
                v-if="itemArchive.isDir"
              >
                <img
                  class="bookcase-bg-img"
                  src="../../../../assets/img/arvhive/bookcase.svg"
                  alt=""
                />
                <div class="bookcase-name" :title="itemArchive.name">
                  {{ itemArchive.name }}
                </div>
              </div>
              <div
                @click="isTherePaging(itemArchive)"
                :id="'mindMap' + itemArchive.cerateTime + index"
                class="mind-map"
                v-else
              >
                {{
                  getBookView(
                    "mindMap" + itemArchive.cerateTime + index,
                    itemArchive
                  )
                }}
              </div>
            </div>
          </template>
          <div class="current-location" v-else>
            {{
              getString(
                loadingText ? strings.Loading : strings.Mind_Group_No_Content
              )
            }}
          </div>
        </div>
      </div>
      <!-- 重置页面 -->
      <div v-else class="association-map-contre-title">
        <div class="association-map-h3">
          <span
            @click="switchArchiveOrRecent(true)"
            class="association-map-recent-creation"
            >{{ getString(strings.My_map) }}</span
          >
          <span
            @click="switchArchiveOrRecent(false)"
            class="association-map-induce"
            >{{ getString(strings.Mind_Archive) }}</span
          >
          <span
            @click="switchArchiveOrRecent('currentPaging')"
            class="association-map-induce"
          >
            {{ getString(strings.Current_Paging) }}
          </span>
          <img
            @click="colseAssocation"
            class="close-button-img"
            src="../../../../assets/img/popupsImg/colse_new.svg"
          />
        </div>
        <div class="association-map-search">
          <div class="left-search">
            <a-button
              class="search-button"
              shape="circle"
              icon="search"
              size="small"
            />
            <input
              class="search-box"
              type="text"
              @focus="useShortcut = false"
              @blur="useShortcut = true"
            />
          </div>
        </div>
        <div class="my-map-list">
          <div class="current-location">
            {{ getString(strings.Loading) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="association-map-contre">
      <!-- 共享组 -->
      <div
        v-if="gmapPageActive == 'gampPaging'"
        class="association-map-contre-title"
      >
        <div class="association-map-h3">
          <span class="association-map-recent-creation association-map-select">
            {{
              getString(strings.Mind_Edit_Page_Sharing_Group) +
              getString(strings.Public_Header_Mind)
            }}
          </span>
          <span
            @click="switchGmapPageActive('gmapCurrentPaging')"
            class="association-map-induce"
          >
            {{ getString(strings.Current_Paging) }}
          </span>
          <!-- <img  @click="colseAssocation" style="width: 18px;float: right;cursor: pointer;" src="../../../../assets/img/myMindIcon/close.svg"/> -->
          <img
            @click="colseAssocation"
            class="close-button-img"
            src="../../../../assets/img/popupsImg/colse_new.svg"
          />
        </div>
        <div class="association-map-search">
          <div class="left-search">
            <a-button
              class="search-button"
              shape="circle"
              icon="search"
              size="small"
              @click="searchButtonGMap()"
            />
            <input
              class="search-box"
              type="text"
              v-model="searchGMapText"
              placeholder="请输入共享组的导图或文件夹"
              v-on:keyup.enter="searchButtonGMap()"
              @focus="useShortcut = false"
              @blur="useShortcut = true"
            />
            <!-- <span  :placeholder="$getStringObj.getString($Strings.Search_Pagination)"
                        class="cleariconfont clear-search-text-icon"
                        v-if="searchText != ''"
                        @click="clearSearchText()"
                        >&#xe605;</span
                        > -->
          </div>
        </div>
        <div v-if="crumbsNav.length > 0" class="crumbs-nav-or-level">
          <div class="ret-lecel" @click="levelClick">
            <img
              class="mind-file-left-img-icon"
              src="../../../../assets/img/groupImg/back-father-file.png"
              alt="返回"
            />
            上一级
          </div>
          <div class="crumbs-nav">
            <div
              class="nav-mind-load"
              v-for="(item, index) in crumbsNav"
              :key="index"
              @click="parentFile(item, index)"
            >
              <template v-if="index != crumbsNav.length - 1">
                <div class="nav-load-name nav-load-text">
                  {{ item.name }}
                </div>
                <img
                  class="mind-load-img"
                  style="margin-top: -16px"
                  src="../../../../assets/img/myMindIcon/right-arrow.svg"
                  alt=""
                />
              </template>
              <template v-else>
                <div class="nav-load-name mind-load-last-text">
                  {{ item.name }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="my-map-list" id="MyGMapList">
          <template v-if="gMapList && gMapList.length > 0">
            <div v-for="(gitem, index) in gMapList" :key="index">
              <div
                v-if="gitem.type == 1"
                class="my-map-list-box"
                @click="gmapIsTherePaging(gitem)"
              >
                <img
                  class="my-mind-img"
                  src="../../../../assets/img/user/plate_plan_4.jpg"
                  v-real-img="checkImgSrc(gitem.cover)"
                  alt=""
                />
                <div class="my-mind-list-text" style="margin: 0 20px 0 15px">
                  <div
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ gitem.name }}
                  </div>
                  <div
                    class="my-mind-main-subheading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ gitem.subtitle }}
                  </div>
                </div>
                <div class="my-mind-time">
                  {{ timeConversion(gitem.modifyTime) }}
                </div>
              </div>
              <div
                v-else
                class="my-map-list-box"
                @click.stop="showGMapcaseBox(gitem)"
              >
                <img
                  class="my-mind-img"
                  src="../../../../assets/img/groupImg/file-icon.png"
                  v-real-img="checkImgSrc(gitem.cover)"
                  alt=""
                />
                <div class="my-mind-list-text" style="margin: 0 20px 0 15px">
                  <div
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ gitem.name }}
                  </div>
                  <div
                    class="my-mind-main-subheading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ gitem.subtitle }}
                  </div>
                </div>
                <div class="my-mind-time">
                  {{ timeConversion(gitem.createTime) }}
                </div>
              </div>
            </div>
          </template>
          <div class="current-location" v-else>
            {{
              getString(
                loadingText ? strings.Loading : strings.Mind_Group_No_Content
              )
            }}
          </div>
        </div>
      </div>
      <!-- 共享组当前分页 -->
      <div
        v-else-if="gmapPageActive == 'gmapCurrentPaging'"
        class="association-map-contre-title"
      >
        <div class="association-map-h3">
          <span
            @click="switchGmapPageActive('gampPaging')"
            class="association-map-recent-creation"
          >
            {{
              getString(strings.Mind_Edit_Page_Sharing_Group) +
              getString(strings.Public_Header_Mind)
            }}
          </span>
          <span class="association-map-induce association-map-select">
            {{ getString(strings.Current_Paging) }}
          </span>
          <img
            @click="colseAssocation"
            class="close-button-img"
            src="../../../../assets/img/popupsImg/colse_new.svg"
          />
        </div>
        <div class="association-map-search">
          <div class="left-search">
            <a-button
              class="search-button"
              shape="circle"
              icon="search"
              size="small"
              @click="searchGmapCurrentPagingBtn()"
            />
            <input
              class="search-box"
              type="text"
              v-model="currentPagingText"
              :placeholder="getString(strings.Search_Pagination)"
              v-on:keyup.enter="searchGmapCurrentPagingBtn()"
              @focus="useShortcut = false"
              @blur="useShortcut = true"
            />
          </div>
        </div>
        <div class="my-map-list" ref="gmapCurrentPagingBox">
          <template
            v-if="gmapCurrentPagingList && gmapCurrentPagingList.length > 0"
          >
            <div
              v-for="(item, index) in gmapCurrentPagingList"
              :key="index"
              class="width-100-percent"
            >
              <div class="my-map-list-box" @click="openMind(item)">
                <img
                  class="my-mind-img"
                  src="../../../../assets/img/user/plate_plan_4.jpg"
                  v-real-img="checkImgSrc(item.cover)"
                  alt=""
                />
                <div class="my-mind-list-text" style="margin: 0 20px 0 15px">
                  <div
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="current-location" v-else>
            {{
              getString(
                loadingText ? strings.Loading : strings.Mind_Group_No_Content
              )
            }}
          </div>
        </div>
      </div>
      <!-- 共享组导图分页 -->
      <div
        v-else-if="gmapPageActive == 'gMappingPaging'"
        class="association-map-contre-title"
      >
        <div class="association-map-h3 text-left">
          <!-- 选择分页 -->
          <span class="select-paging"
            >{{ getString(strings.Select_Paging) }}（{{
              gmapCurrentPagingList.length
            }}）</span
          >
          <img
            @click="colseMappingPaging"
            class="close-button-img"
            src="../../../../assets/img/popupsImg/colse_new.svg"
          />
        </div>
        <div class="association-map-search">
          <div class="left-search">
            <a-button
              class="search-button"
              shape="circle"
              icon="search"
              size="small"
              @click="searchGMappingPaging()"
            />
            <input
              class="search-box"
              type="text"
              v-model="currentPagingText"
              :placeholder="getString(strings.Search_Pagination)"
              v-on:keyup.enter="searchGMappingPaging()"
              @focus="useShortcut = false"
              @blur="useShortcut = true"
            />
          </div>
        </div>
        <div class="my-map-list" ref="gmapMappingPagingBox">
          <template
            v-if="gmapCurrentPagingList && gmapCurrentPagingList.length > 0"
          >
            <div
              v-for="(item, index) in gmapCurrentPagingList"
              :key="index"
              class="width-100-percent"
            >
              <div class="my-map-list-box" @click="openMind(item)">
                <img
                  class="my-mind-img"
                  src="../../../../assets/img/user/plate_plan_4.jpg"
                  v-real-img="checkImgSrc(item.cover)"
                  alt=""
                />
                <div class="my-mind-list-text" style="margin: 0 20px 0 15px">
                  <div
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - 118 + 'px' }"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="current-location" v-else>
            {{
              getString(
                loadingText ? strings.Loading : strings.Mind_Group_No_Content
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import HttpServerConfig from "../../../../common/HttpServerConfig";
import getString from "../../../../common/lang/language";
import strings from "../../../../common/lang/strings";
import {
  postMindmapQuery,
  postMindmapSearchArchive,
  postMindmapSearchSheet,
} from "../../../../common/netWork/mind_map_api";
import {
  postMindmapQueryArchives,
  postMindmapListSheets,
  postMindmapListDir,
} from "../../../../common/netWork/networkcache";

import {
  postGroupListGfiles,
  postGroupSearchGfiles,
  postGroupListGsheets,
  postGroupSearchGsheet,
} from "../../../../common/netWork/group_api";
import dataTimeToFormat from "../../../../utils/timestampToTime";
import BookData from "../../../../views/book/data/BookData";
import BookView from "../../../../views/book/ui/BookView";
import EditMindmapVM from "../../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import MindmapDataRouter from '../../../../viewmodel/facade/MindmapDataRouter';
export default {
  props: ["groupId"],
  data() {
    return {
      strings,
      spinning: false, //是否展示加载动画
      loadingText: false, //加载中
      recentMapList: [], //我的导图数据list集合
      currentPagingList: [], //当前分页数据list集合
      gmapCurrentPagingList: [], //共享组当前分页数据list集合
      fileMapList: [], //我的归纳数据list集合
      fullWidth: 730 * 0.8,
      searchMapText: "", //我的导图查询的query
      currentPagingText: "", //当前分页查询的query
      searchArchiveText: "", //我的归纳查询的query
      isArchiveOrRecent: true, //判断是最近创作、归档、当前分页
      isArchiveOrRecentTem: "",
      mappingPagingId: "", //导图选择分页id
      isMapData: true, //判断是否还有导图归纳数据
      isGMapData: true, //判断是否还有共享组导图数据

      isMyMap: true, //判断是共享组还是我的导图
      gmapPageActive: "gampPaging", //判断是gampPaging:共享组、gampCurrentPaging:当前分页
      gMapList: [], //共享组数据list集合
      searchGMapText: "", //共享组查询的query
      page: 1,

      crumbsNav: [],
      recentMapFlage: true,
      fileMapFlage: true,
      gMapListFlage: true,
      loadMorePagingDataFlag: false, //分页是否可以请求更多列表数据
      totalNumPaging: 1, //分页数据总数
      loadMorePagingDataTime: null,
      lastIndexPaging: 0, //分页上一批数据的最后一个index
    };
  },
  created() {
    let routeName = this.$route.name;
    if (routeName == "Complie") {
      this.isMyMap = true;
      this.isArchiveOrRecent = true;
      this.getMindMapList("", (res) => {
        this.recentMapList = res.entities;
      });
    } else if (routeName == "arvhiveMap") {
      this.isMyMap = true;
      this.isArchiveOrRecent = false;
      this.getMindmapArchive("", 0, (res) => {
        this.fileMapList = res.archives;
      });
    } else if (routeName == "gmap") {
      this.isMyMap = false;
      this.getGMapList();
    }
  },
  mounted() {
    this.loadMoreMapData();
  },
  methods: {
    ...mapMutations(["setIsAssociationModel"]),
    //关闭关联导图
    colseAssocation() {
      this.setIsAssociationModel(false);
    },
    //关闭选择分页
    colseMappingPaging() {
      this.isArchiveOrRecent = this.gmapPageActive= this.isArchiveOrRecentTem;
    },
    switchArchiveOrRecent(val) {
      this.isArchiveOrRecent = null; //重置页面
      setTimeout(() => {
        //延迟清除svg
        this.isArchiveOrRecent = val;
      }, 50);
      if (val === true) {
        //最近创作
        this.getMindMapList("", (res) => {
          this.recentMapList = res.entities;
        });
      } else if (val == "currentPaging") {
        // 当前分页
        this.getCurrentPagingList(0);
      } else {
        //归档
        this.fileMapList = [];
        this.getMindmapArchive("", 0, (res) => {
          this.fileMapList = res.archives;
        });
      }
      this.crumbsNav = [];
      this.loadMoreMapData();
      //   console.log(this.isArchiveOrRecent);
    },
    switchGmapPageActive(val) {
      this.gmapPageActive = val;
      if (val == "gampPaging") {
        // 共享组
        this.getGMapList();
      } else if (val == "gmapCurrentPaging") {
        // 共享组当前分页
        this.getGmapCurrentPagingList(0);
      }
    },
    getString(i) {
      return getString(i);
    },
    searchButton() {
      if (this.searchMapText == "") {
        this.getMindMapList("", (res) => {
          this.crumbsNav = [];
          this.recentMapList = res.entities;
        });
      } else {
        this.searchMap("").then((res) => {
          this.crumbsNav = [
            {
              parentId: "",
              name: this.searchMapText,
              query: this.searchMapText,
            },
          ];
          this.recentMapList = res.entities;
        });
      }
    },
    // 搜索当前分页
    searchCurrentPagingBtn() {
      if (this.currentPagingText == "") {
        this.getCurrentPagingList(0);
      } else {
        let rootId = this.$route.query.id;
        let sheetId = this.$route.query.sheetId;
        postMindmapSearchSheet(
          { rootId, query: this.currentPagingText },
          (res) => {
            if (res) {
              let sheet = res.sheets;
              if (sheet) {
                sheet = sheet.filter(
                  (item) => item.id != (sheetId ? sheetId : rootId)
                );
                this.currentPagingList = sheet;
              } else {
                this.currentPagingList = [];
              }
            }
          },
          (e) => {
            this.$message.error(e.desc);
          }
        );
      }
    },
    // 搜索共享组当前分页
    searchGmapCurrentPagingBtn() {
      if (this.currentPagingText == "") {
        this.getGmapCurrentPagingList(0);
      } else {
        let rootId = this.$route.query.id;
        let sheetId = this.$route.query.sheetId;
        let groupId = this.groupId;
        postGroupSearchGsheet(
          { rootId, groupId, query: this.currentPagingText },
          (res) => {
            if (res) {
              let sheet = res.sheets;
              if (sheet) {
                sheet = sheet.filter(
                  (item) => item.id != (sheetId ? sheetId : rootId)
                );
                this.gmapCurrentPagingList = sheet;
              } else {
                this.gmapCurrentPagingList = [];
              }
            }
          },
          (e) => {
            this.$message.error(e.desc);
          }
        );
      }
    },
    // 搜索导图分页
    searchMappingPagingBtn() {
      let rootId = this.mappingPagingId;
      if (this.currentPagingText == "") {
        this.getMappingPagingList(rootId, 0);
      } else {
        postMindmapSearchSheet(
          { rootId, query: this.currentPagingText },
          (res) => {
            if (!!res) {
              let sheets = res.sheets;
              if (sheets) {
                this.currentPagingList = sheets;
              } else {
                this.currentPagingList = [];
              }
            }
          },
          (e) => {
            this.$message.error(e.desc);
          }
        );
      }
    },
    // 搜索共享组导图分页
    searchGMappingPaging() {
      let rootId = this.mappingPagingId;
      let groupId = this.groupId;
      if (this.currentPagingText == "") {
        this.getGMappingPagingData(rootId, 0);
      } else {
        postGroupSearchGsheet(
          { rootId, groupId, query: this.currentPagingText },
          (res) => {
            if (res) {
              let sheet = res.sheets;
              if (sheet) {
                this.gmapCurrentPagingList = sheet;
              } else {
                this.gmapCurrentPagingList = [];
              }
            }
          },
          (e) => {
            this.$message.error(e.desc);
          }
        );
      }
    },
    searchMap() {
      let that = this;
      return new Promise(function (resolve, reject) {
        postMindmapQuery(
          { query: that.searchMapText, parentId: "" },
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    timeConversion(tim) {
      return dataTimeToFormat.formatTime(tim);
    },
    checkImgSrc(src) {
      return HttpServerConfig.getMediaSrc(src);
    },
    //获取最近创作数据
    getMindMapList(id, resolve, reject, ev) {
      this.loadingText = true;
      postMindmapListDir(
        { parentId: id },
        (res) => {
          if (resolve) resolve(res);
          this.recentMapFlage = true;
          this.loadingText = false;
        },
        (error) => {
          if (reject) reject(error);
          that.$message.error(error.desc);
          this.loadingText = false;
        },
        ev
      );
    },
    //获取当前分页数据
    getCurrentPagingList(lastIndex) {
      let rootId = this.$route.query.id;
      let sheetId = this.$route.query.sheetId;
      this.loadingText = true;
      MindmapDataRouter.postMindmapSheets(
        { rootId, lastIndex },
        (res) => {
          if (!!res) {
            this.totalNumPaging = res.totalNum;
            let sheet = res.sheets;
            if (sheet && sheet.length > 0) {
              if (sheetId) {
                sheet = sheet.filter((item) => item.id != sheetId); //过滤当前分页
              } else {
                sheet = sheet.filter((item) => item.id != rootId); //过滤当前导图
              }
              this.currentPagingList = sheet;
              if (this.currentPagingList && this.currentPagingList.length > 0) {
                if (this.currentPagingList.length <= this.totalNumPaging) {
                  //数据数量小于等于分页数据总数加载更多
                  this.loadMorePagingDataFlag = true;
                  this.lastIndexPaging =
                    this.currentPagingList[
                      this.currentPagingList.length - 1
                    ].index;
                  this.loadMorePagingDataEvent(rootId);
                }
              }
            } else {
              this.currentPagingList = [];
            }
          }
          this.loadingText = false;
        },
        (e) => {
          this.$message.error(e.desc);
        }
      );
    },
    //获取共享组当前分页数据
    getGmapCurrentPagingList(lastIndex) {
      let rootId = this.$route.query.id;
      let groupId = this.groupId;
      let sheetId = this.$route.query.sheetId;
      this.loadingText = true;
      postGroupListGsheets(
        { rootId, groupId, lastIndex },
        (res) => {
          if (res) {
            this.totalNumPaging = res.totalNum;
            let sheet = res.sheets;
            if (sheet && sheet.length > 0) {
              if (sheetId) {
                sheet = sheet.filter((item) => item.id != sheetId); //过滤当前分页
              } else {
                sheet = sheet.filter((item) => item.id != rootId); //过滤当前导图
              }
              if (sheet.length <= this.totalNumPaging) {
                //数据数量小于等于分页数据总数加载更多
                this.loadMorePagingDataFlag = true;
                this.lastIndexPaging = sheet[sheet.length - 1].index;
                this.gMapLoadMorePagingDataEvent(rootId);
              }
              this.gmapCurrentPagingList = sheet;
            } else {
              this.gmapCurrentPagingList = [];
            }
          }
          this.loadingText = false;
        },
        (e) => {
          this.$message.error(e.desc);
        }
      );
    },
    //获取导图分页数据
    getMappingPagingList(rootId, lastIndex) {
      this.loadingText = true;
      MindmapDataRouter.postMindmapSheets(
        { rootId, lastIndex },
        (res) => {
          if (!!res) {
            this.totalNumPaging = res.totalNum;
            let sheets = res.sheets;
            if (sheets && sheets.length > 0) {
              this.currentPagingList = sheets;
              if (this.currentPagingList && this.currentPagingList.length > 0) {
                if (this.currentPagingList.length <= this.totalNumPaging) {
                  //数据数量小于等于分页数据总数加载更多
                  this.loadMorePagingDataFlag = true;
                  this.lastIndexPaging =
                    this.currentPagingList[
                      this.currentPagingList.length - 1
                    ].index;
                  this.loadMorePagingDataEvent(rootId);
                }
              }
            } else {
              this.currentPagingList = [];
            }
          }
          this.loadingText = false;
        },
        (e) => {
          this.$message.error(e.desc);
        }
      );
    },
    //获取共享组导图分页数据
    getGMappingPagingData(rootId, lastIndex) {
      this.loadingText = true;
      let groupId = this.groupId;
      postGroupListGsheets(
        { rootId, groupId, lastIndex },
        (res) => {
          if (res) {
            this.totalNumPaging = res.totalNum;
            let sheet = res.sheets;
            if (sheet && sheet.length > 0) {
              if (sheet.length <= this.totalNumPaging) {
                //数据数量小于等于分页数据总数加载更多
                this.loadMorePagingDataFlag = true;
                this.lastIndexPaging = sheet[sheet.length - 1].index;
                this.gMapLoadMorePagingDataEvent(rootId);
              }
              this.gmapCurrentPagingList = sheet;
            } else {
              this.gmapCurrentPagingList = [];
            }
          }
          this.loadingText = false;
        },
        (e) => {
          this.$message.error(e.desc);
        }
      );
    },
    // 分页添加滑动加载更多数据事件
    loadMorePagingDataEvent(rootId) {
      this.$nextTick(() => {
        let box = null;
        if (this.isArchiveOrRecent == "currentPaging") {
          box = this.$refs.currentPagingBox;
        } else {
          box = this.$refs.mappingPagingBox;
        }
        if (box) {
          const onMouseWheel = (e) => {
            clearTimeout(this.loadMorePagingDataTime);
            this.loadMorePagingDataTime = setTimeout(() => {
              let ev = e || window.event;
              let down = true; // 定义一个标志，当滚轮向下滚时，执行一些操作
              down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
              if (down) {
                if (this.loadMorePagingDataFlag) {
                  this.loadMorePagingList(rootId);
                } else {
                  box.removeEventListener("mousewheel", onMouseWheel, true);
                }
              }
            }, 100);
          };
          box.addEventListener("mousewheel", onMouseWheel, true);
        }
      });
    },
    // 分页加载更多数据
    loadMorePagingList(rootId) {
      if (this.loadMorePagingDataFlag) {
        const obj = {
          lastIndex: this.lastIndexPaging,
          rootId,
        };
        MindmapDataRouter.postMindmapSheets(
          obj,
          (res) => {
            let sheets = res.sheets;
            if (!!sheets && sheets.length > 0) {
              this.currentPagingList = [...this.currentPagingList, ...sheets];
              this.loadMorePagingDataFlag = true;
              this.lastIndexPaging =
                this.currentPagingList[this.currentPagingList.length - 1].index;
            } else {
              this.loadMorePagingDataFlag = false;
            }
            // console.log(this.currentPagingList,this.lastIndexPaging);
          },
          (e) => {
            this.$message.error(e.desc);
          }
        );
      }
    },
    // 共享组分页添加滑动加载更多数据事件
    gMapLoadMorePagingDataEvent(rootId) {
      this.$nextTick(() => {
        let box = null;
        if (this.gmapPageActive == "gmapCurrentPaging") {
          box = this.$refs.gmapCurrentPagingBox;
        } else {
          box = this.$refs.gmapMappingPagingBox;
        }
        if (box) {
          const onMouseWheel = (e) => {
            clearTimeout(this.loadMorePagingDataTime);
            this.loadMorePagingDataTime = setTimeout(() => {
              let ev = e || window.event;
              let down = true; // 定义一个标志，当滚轮向下滚时，执行一些操作
              down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
              if (down) {
                if (this.loadMorePagingDataFlag) {
                  this.gMapLoadMorePagingList(rootId);
                } else {
                  box.removeEventListener("mousewheel", onMouseWheel, true);
                }
              }
            }, 100);
          };
          box.addEventListener("mousewheel", onMouseWheel, true);
        }
      });
    },
    // 共享组分页加载更多数据
    gMapLoadMorePagingList(rootId) {
      if (this.loadMorePagingDataFlag) {
        const obj = {
          lastIndex: this.lastIndexPaging,
          rootId,
          groupId: this.groupId,
        };
        postGroupListGsheets(
          obj,
          (res) => {
            let sheet = res.sheets;
            if (sheet && sheet.length > 0) {
              this.gmapCurrentPagingList = [
                ...this.gmapCurrentPagingList,
                ...sheet,
              ];
              this.loadMorePagingDataFlag = true;
              this.lastIndexPaging =
                this.gmapCurrentPagingList[
                  this.gmapCurrentPagingList.length - 1
                ].index;
            } else {
              this.loadMorePagingDataFlag = false;
            }
            // console.log(this.gmapCurrentPagingList,this.lastIndexPaging);
          },
          (e) => {
            this.$message.error(e.desc);
          }
        );
      }
    },
    //获取归档数据
    getMindmapArchive(id, lastIndex, resolve, reject, ev) {
      this.loadingText = true;
      postMindmapQueryArchives(
        { parentId: id, lastIndex: lastIndex, dirOnly: false },
        (res) => {
          if (resolve) resolve(res);
          this.fileMapFlage = true;
          this.loadingText = false;
        },
        (error) => {
          if (reject) reject(error);
          that.$message.error(error.desc);
          this.loadingText = false;
        },
        ev
      );
    },
    //获取共享组数据
    getGMapList() {
      this.page = 1;
      let obj = {
        groupId: this.groupId,
        tab: 0,
        parentId: "",
        page: this.page,
      };
      this.getMindGMapList(obj).then((res) => {
        if (res) {
          let gfile = res.gfiles;
          if (gfile) {
            this.gMapList = gfile;
          }
        }
      });
    },
    //封装共享组接口
    getMindGMapList(obj) {
      this.loadingText = true;
      return new Promise((resolve, reject) => {
        postGroupListGfiles(
          obj,
          (res) => {
            resolve(res);
            this.gMapListFlage = true;
            this.loadingText = false;
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
            this.loadingText = false;
          }
        );
      });
    },
    searchButtonArchive() {
      this.fileMapList = [];
      if (this.searchArchiveText == "") {
        this.getMindmapArchive("", 0, (res) => {
          this.crumbsNav = [];
          this.fileMapList = res.archives;
        });
      } else {
        this.searchMapArchive("").then((res) => {
          this.crumbsNav = [
            {
              parentId: "",
              name: this.searchArchiveText,
              query: this.searchArchiveText,
            },
          ];
          this.fileMapList = res.archives;
        });
      }
    },
    searchMapArchive() {
      let that = this;
      return new Promise(function (resolve, reject) {
        postMindmapSearchArchive(
          { query: that.searchArchiveText },
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    showMapcaseBox(obj) {
      this.recentMapList = [];
      let isSecondCallback = false;
      this.getMindMapList(obj.id, (res) => {
        let crus = { parentId: obj.id, name: obj.name, query: "" };
        if (!isSecondCallback) {
          this.crumbsNav.push(crus);
        }
        isSecondCallback = true;
        this.recentMapList = res.entities;
      });
    },
    showBookcaseBox(obj) {
      this.fileMapList = [];
      let isSecondCallback = false;
      this.getMindmapArchive(obj.id, 0, (res) => {
        let crus = { parentId: obj.id, name: obj.name, query: "" };
        if (!isSecondCallback) {
          this.crumbsNav.push(crus);
        }
        isSecondCallback = true;
        this.fileMapList = res.archives;
        this.isMapData = true;
      });
      // console.log(this.crumbsNav);
    },
    getBookView(contextName, mindData) {
      this.$nextTick(() => {
        let context = document.getElementById(contextName);
        if (context) {
          context.innerHTML = ""; //删除DOM节点的子元素
          let bookData = new BookData();
          bookData.id = mindData.mindMapId;
          bookData.name = mindData.name;
          bookData.sheetNumber = mindData.sheetNum;
          bookData.url = mindData.cover;
          bookData.color =
            mindData.color == 16777215 ? 0xf9eae7 : mindData.color; //背景色是透明色换成粉色
          bookData.time = mindData.cerateTime * 1000;
          let bookView = new BookView(context, bookData);
        }
      });
    },
    //是否有分页
    isTherePaging(item) {
      if (item.sheetNum > 0) {
        let mindMapId = item.mindMapId;
        this.isArchiveOrRecentTem = this.isArchiveOrRecent;
        this.isArchiveOrRecent = "MappingPaging";
        this.mappingPagingId = mindMapId;
        this.getMappingPagingList(mindMapId, 0);
      } else {
        this.openMind(item);
      }
    },
    //共享组是否有分页
    gmapIsTherePaging(item) {
      if (item.sheetNum > 1) {
        let mindId = item.gmapId;
        this.isArchiveOrRecentTem = this.gmapPageActive;
        this.gmapPageActive = "gMappingPaging";
        this.mappingPagingId = mindId;
        this.getGMappingPagingData(mindId, 0);
      } else {
        this.openMindGMap(item);
      }
    },
    openMind(item) {
      let that = this;
      this.$confirm({
        title: that.getString(that.strings.Mind_Tips),
        content: that.getString(that.strings.Mind_confirm_Association_Map),
        cancelText: that.getString(that.strings.Global_Cancel),
        okText: that.getString(that.strings.Global_Ok),
        centered: true,
        style: {
          borderRadius: "20px",
        },
        onOk() {
          let mssage = null;
          if (item.rootId) {
            //关联导图使用item.mindMapId，关联分页使用item.rootId
            mssage = EditMindmapVM.setMindIdToNodeForConnectMap(
              EditMindmapVM.selectedNodeId,
              -1,
              item.rootId,
              item.id
            );
          } else {
            mssage = EditMindmapVM.setMindIdToNodeForConnectMap(
              EditMindmapVM.selectedNodeId,
              -1,
              item.mindMapId,
              ""
            );
          }
          if (mssage.uuid) {
            {
              // EditMindmapVM.delegate.exitEditStatus();// 退出编辑状态，保存导图数据
              EditMindmapVM.saveDataForClosePage();
            } //保存导图
            that.$message.success(that.getString(strings.Linked_Successfully)); //关联成功
          }
          that.setIsAssociationModel(false);
        },
        onCancel() {},
      });
    },

    showGMapcaseBox(gitem) {
      this.gMapList = [];
      this.isGMapData = true;
      this.page = 1;
      let obj = {
        groupId: this.groupId,
        tab: 0,
        parentId: gitem.id,
        page: this.page,
      };
      let isSecondCallback = false;
      this.getMindGMapList(obj).then((res) => {
        let crus = { parentId: gitem.id, name: gitem.name, query: "" };
        if (!isSecondCallback) {
          this.crumbsNav.push(crus);
        }
        isSecondCallback = true;
        this.gMapList = res.gfiles;
      });
    },
    openMindGMap(item) {
      let that = this;
      this.$confirm({
        title: that.getString(that.strings.Mind_Tips),
        content: that.getString(that.strings.Mind_confirm_Association_Map),
        cancelText: that.getString(that.strings.Global_Cancel),
        okText: that.getString(that.strings.Global_Ok),
        centered: true,
        style: {
          borderRadius: "20px",
        },
        onOk() {
          let mssage = null;
          if (item.rootId) {
            //关联导图使用item.mindMapId，关联分页使用item.rootId
            mssage = EditMindmapVM.setMindIdToNodeForConnectMap(
              EditMindmapVM.selectedNodeId,
              -1,
              item.rootId,
              item.id
            );
          } else {
            mssage = EditMindmapVM.setMindIdToNodeForConnectMap(
              EditMindmapVM.selectedNodeId,
              -1,
              item.gmapId,
              ""
            );
          }
          if (mssage.uuid) {
            {
              EditMindmapVM.saveDataForClosePage();
            } //保存导图
            that.$message.success(that.getString(strings.Linked_Successfully)); //关联成功
          }
          that.setIsAssociationModel(false);
        },
        onCancel() {},
      });
    },
    searchButtonGMap() {
      if (this.searchGMapText == "") {
        this.isGMapData = true;
        this.page = 1;
        let obj = {
          groupId: this.groupId,
          tab: 0,
          parentId: "",
          page: this.page,
        };
        this.getMindGMapList(obj).then((res) => {
          this.gMapList = res.gfiles;
        });
      } else {
        this.searchGMap().then((res) => {
          this.crumbsNav = [
            {
              parentId: "",
              name: this.searchGMapText,
              query: this.searchGMapText,
            },
          ];
          this.gMapList = res.gfiles;
        });
      }
    },
    searchGMap() {
      let that = this;
      return new Promise(function (resolve, reject) {
        postGroupSearchGfiles(
          {
            groupId: that.groupId,
            query: that.searchGMapText,
            tab: 0,
            parentId: "",
          },
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },

    loadMoreMapData() {
      this.$nextTick(() => {
        let box;
        if (this.isMyMap) {
          box = document.getElementById("MyMapList");
        } else {
          box = document.getElementById("MyGMapList");
        }
        if (box) {
          const onMouseWheel = (e) => {
            clearTimeout(this.loadMoreDataTime);
            this.loadMoreDataTime = setTimeout(() => {
              let ev = e || window.event;
              let down = true; // 定义一个标志，当滚轮向下滚时，执行一些操作
              down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
              if (down) {
                if (this.isMyMap) {
                  if (!this.isArchiveOrRecent) {
                    let lastIndex =
                      this.fileMapList[this.fileMapList.length - 1].index;
                    if (!this.isMapData) {
                      return;
                    }
                    this.getMindmapArchive("", lastIndex, (res) => {
                      if (res.archives.length > 0) {
                        // this.fileMapList.push(res.archives)
                        this.fileMapList = [
                          ...this.fileMapList,
                          ...res.archives,
                        ];
                      } else {
                        this.isMapData = false;
                      }

                      //    = this.fileMapList + res.archives;
                    });
                  }
                } else {
                  if (!this.isGMapData) {
                    return;
                  }
                  this.page = this.page + 1;
                  let obj = {
                    groupId: this.groupId,
                    tab: 0,
                    parentId: "",
                    page: this.page,
                  };
                  this.getMindGMapList(obj).then((res) => {
                    if (res.gfiles != null && res.gfiles.length > 0) {
                      this.gMapList = [...this.gMapList, ...res.gfiles];
                    } else {
                      this.isGMapData = false;
                    }
                  });
                }
              }
            }, 100);
          };
          box.removeEventListener("mousewheel", onMouseWheel, true);
          box.addEventListener("mousewheel", onMouseWheel, true);
        }
      });
    },
    parentFile(itm, index) {
      let crusCount = this.crumbsNav.length - index;
      if (this.isMyMap) {
        if (this.isArchiveOrRecent) {
          if (itm.parentId == "" || itm.parentId == null) {
            this.searchMapText = itm.query;
            this.searchMap("").then((res) => {
              this.crumbsNav = [
                { parentId: "", name: itm.name, query: itm.query },
              ];
              this.recentMapList = res.entities;
            });
          } else {
            this.getMindMapList(itm.parentId, (res) => {
              this.crumbsNav.splice(index + 1, crusCount);
              this.recentMapList = res.entities;
            });
          }
        } else {
          this.fileMapList = [];
          if (itm.parentId == "" || itm.parentId == null) {
            this.searchArchiveText = itm.query;
            this.searchMapArchive("").then((res) => {
              this.crumbsNav = [
                { parentId: "", name: itm.name, query: itm.query },
              ];
              this.fileMapList = res.archives;
            });
          } else {
            this.getMindmapArchive(itm.parentId, 0, (res) => {
              this.crumbsNav.splice(index + 1, crusCount);
              this.fileMapList = res.archives;
              this.isMapData = true;
            });
          }
        }
      } else {
        if (itm.parentId == "" || itm.parentId == null) {
          this.searchGMapText = itm.query;
          this.searchGMap().then((res) => {
            this.crumbsNav = [
              { parentId: "", name: itm.name, query: itm.query },
            ];
            this.gMapList = res.gfiles;
          });
        } else {
          this.isGMapData = true;
          this.page = 1;
          let obj = {
            groupId: this.groupId,
            tab: 0,
            parentId: itm.parentId,
            page: this.page,
          };
          this.getMindGMapList(obj).then((res) => {
            this.crumbsNav.splice(index + 1, crusCount);
            this.gMapList = res.gfiles;
          });
        }
      }
      console.log(this.crumbsNav);
    },
    levelClick() {
      let crusCount = this.crumbsNav.length;
      if (crusCount == 1) {
        this.crumbsNav = [];
        if (this.isMyMap) {
          if (this.isArchiveOrRecent) {
            this.recentMapList = [];
            this.getMindMapList("", (res) => {
              this.recentMapList = res.entities;
            });
          } else {
            this.fileMapList = [];
            this.getMindmapArchive("", 0, (res) => {
              this.fileMapList = [];
              setTimeout(() => {
                this.fileMapList = res.archives;
              }, 100);
            });
          }
        } else {
          this.page = 1;
          this.gMapList = [];
          let obj = {
            groupId: this.groupId,
            tab: 0,
            parentId: "",
            page: this.page,
          };
          this.getMindGMapList(obj).then((res) => {
            this.gMapList = res.gfiles;
          });
        }
      } else {
        this.parentFile(this.crumbsNav[crusCount - 2], crusCount - 2);
      }
    },
    arrayObjectUnique(array) {
      //数组对象去重
      var r = [];
      for (var i = 0, l = array.length; i < l; i++) {
        for (var j = i + 1; j < l; j++) {
          if (array[i].id === array[j].id) {
            j = ++i;
          }
        }
        r.push(array[i]);
      }
      return r;
    },
  },
  watch: {
    recentMapList(newVal) {
      if (newVal && newVal.length > 0 && this.recentMapFlage) {
        let rootId = this.$route.query.id;
        newVal = newVal.filter((item) => item.mindMapId != rootId);
        this.recentMapList = newVal;
        this.recentMapFlage = false;
      }
    },
    fileMapList(newVal) {
      if (newVal && newVal.length > 0 && this.fileMapFlage) {
        let rootId = this.$route.query.id;
        newVal = newVal.filter((item) => item.mindMapId != rootId);
        this.fileMapList = newVal;
        this.fileMapFlage = false;
      }
    },
    gMapList(newVal) {
      if (newVal && newVal.length > 0 && this.gMapListFlage) {
        let rootId = this.$route.query.id;
        newVal = newVal.filter((item) => item.gmapId != rootId);
        this.gMapList = newVal;
        this.gMapListFlage = false;
      }
    },
    // crumbsNav(newVal) {
    //     if (newVal && newVal.length > 0) {
    //         this.crumbsNav = this.arrayObjectUnique(this.crumbsNav);
    //     }
    // },
  },
  updated() {},
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "fileiconfont";
  src: url("../../../../assets/font-icon/font_tclfjh9pcm/iconfont.eot");
  src: url("../../../../assets/font-icon/font_tclfjh9pcm/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../../assets/font-icon/font_tclfjh9pcm/iconfont.woff2")
      format("woff2"),
    url("../../../../assets/font-icon/font_tclfjh9pcm/iconfont.woff")
      format("woff"),
    url("../../../../assets/font-icon/font_tclfjh9pcm/iconfont.ttf")
      format("truetype"),
    url("../../../../assets/font-icon/font_tclfjh9pcm/iconfont.svg#iconfont")
      format("svg");
}
.fileiconfont {
  font-family: "fileiconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 文件夹颜色开始 */
.file-color-green {
  color: #0bbb4e;
}
.file-color-blue {
  color: #6bb7da;
}
.file-color-red {
  color: #ff4d14;
}
.file-color-yellow {
  color: #ebcb3c;
}
.file-color-gray {
  color: #707070;
}

/* 加载动画开始 */
.loading-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 300;
}
.loading-child {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(2);
}
.ant-spin-dot-item {
  background-color: #fd492b;
}
.ant-spin {
  color: #fd492b;
}
.ant-spin .ant-spin-dot-item {
  background-color: #fd492b !important;
}
/* 加载动画结束 */

.association-map-model {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}
.association-map-contre {
  width: 730px;
  height: 586px;
  border-radius: 9px;
  z-index: 9999;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  overflow: hidden;
}
.association-map-contre-title {
  // margin-top: 20px;
}
.association-map-h3 {
  width: 100%;
  padding: 10px 15px;
  display: inline-block;
  text-align: center;
  background: #efefef;
  font-family: PingFang SC;
}
.association-map-recent-creation {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.association-gmap-recent-creation {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.association-map-recent-creation:hover {
  color: #d6301a;
}
.association-map-induce {
  font-size: 16px;
  font-weight: 600;
  margin-left: 48px;
  cursor: pointer;
}
.association-map-induce:hover {
  color: #d6301a;
}
.select-paging {
  font-size: 16px;
  color: #333;
  margin-left: 15px;
}
.association-map-search {
  width: 90%;
  margin: 0 auto;
  height: 60px;
}
.left-search {
  width: 100%;
  height: 30px;
  position: relative;
  float: left;
  margin-top: 30px;
  .clear-search-text-icon {
    display: block;
    position: absolute;
    top: 0;
    font-size: 18px;
    left: 228px;
    height: 30px;
    line-height: 32px;
    color: #bbb;
    cursor: pointer;
    &:hover {
      color: #ff7354;
    }
    &:active {
      color: #d6301a;
    }
  }

  .search-button {
    position: absolute;
    margin-left: 10px;
    margin-top: 3px;
    border: 0px;
    background: none;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    background: #fd492b;
    color: #fff;
    opacity: 1;
    border: none;
  }
  .search-box {
    width: 100%;
    border-radius: 15px;
    border: transparent;
    background: #f0f2f8;
    margin-right: 10px;
    height: 30px;
    padding-left: 35px;
    padding-right: 30px;
  }
  .search-box:focus {
    outline: none;
  }
}
.my-map-list {
  width: 90%;
  margin: 0px auto;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  height: 360px;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
  .current-location {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-size: 16px;
  }
}
.my-map-list-box {
  position: relative;
  display: flex;
  height: 60px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.my-map-list-box:hover {
  // background-color: #ddd;
  background-color: #eee;
}
.my-mind-img {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 31px;
  text-align: center;
  margin-left: 20px;
  border: 1px solid #e9e9e9;
  /* box-shadow: 0 0 5px #999; */
  border-radius: 6px;
  border-radius: 2px;
}
.my-mind-main-heading {
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  color: #333333;
  min-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-mind-main-subheading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: #999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.my-mind-time {
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  color: #333333;
  width: 118px;
}
.my-mind-icon {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 41px;
  text-align: center;
  margin-left: 20px;
}
.book-select {
  width: 120px;
  cursor: pointer;
  position: relative;
  z-index: 100;
  margin-right: 10px;
}
.bookcase-item {
  width: 120px;
  cursor: pointer;
  position: relative;
  z-index: 100;
  margin-right: 30px;
  margin-top: 22px;
  &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
  .bookcase-bg-img {
    width: 100%;
  }
  .bookcase-name {
    width: 100%;
    color: #5d2d1b;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 78px;
    padding: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.mind-map {
  width: 95px;
  height: 117px;
  margin-top: 20px;
  margin-right: 30px;
  position: relative;
  z-index: 200;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
}
.association-map-select {
  color: #fd5438;
  border-bottom: 2px solid #fd5438;
}
.crumbs-nav-or-level {
  width: 90%;
  margin: 0px auto;
  margin-top: 20px;
}
.ret-lecel {
  font-size: 16px;
  width: 80px;
  position: relative;
  float: left;
  cursor: pointer;
  color: #fb8686;
}
.crumbs-nav {
  width: 577px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 30px;
  display: inline-block;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
}
.nav-mind-load {
  display: inline-block;
  width: 90px;
  text-align: center;
}
.nav-load-name {
  display: inline-block;
  font-size: 16px;
  width: 64px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
.nav-load-text {
  color: #cccccc;
}
.nav-load-text:hover {
  color: #626262;
}
.mind-load-last-text {
  color: #353535;
}
.mind-load-last-text:hover {
  color: #000000;
}
.close-button-img {
  cursor: pointer;
  opacity: 0.5;
  width: 18px;
  float: right;
  margin-top: 5px;
}
.close-button-img:hover {
  opacity: 1;
}
</style>