<template>
  <!-- 分享到社区 -->
  <div id="share-potato-chips">
    <div class="share-pc-box">
      <a-form
        :form="shareForm"
        :label-col="{ span: 5 }"
        @submit="handleSharePotatoChips"
      >
        <div class="share-title-box">
          <div class="share-title-text">
            <span>{{ getString(strings.Mind_Edit_Left_Menu_Share) }}</span>
          </div>
          <div class="share-close-model" @click="colseSharePotatoChips">
            <img
              class="close-button-img"
              src="../../assets/img/popupsImg/colse_new.svg"
              alt=""
            />
          </div>
        </div>
        <!-- 标题封面 -->
        <div class="share-cover-text">
          <div class="share-cover">
            <img :src="httpImageTo() + shareCategoryCover.cover" alt="" />
          </div>
          <div class="share-text">
            <div class="share-text-title">
              <a-form-item>
                <a-textarea
                  style="resize: none; border: none"
                  v-decorator="[
                    'title',
                    {
                      rules: [
                        {
                          message: getString(
                            strings.Mind_Edit_Left_Menu_Main_Title_Cannot_Be_Empty
                          ),
                        },
                      ],
                    },
                  ]"
                  :maxLength="66"
                />
              </a-form-item>
            </div>
            <!-- <div class="share-text-division"></div> -->
            <div class="share-text-subtitle">
              <a-form-item>
                <a-textarea
                  style="resize: none; border: none"
                  v-decorator="['subtitle']"
                  :maxLength="240"
                />
              </a-form-item>
            </div>
          </div>
        </div>
        <!-- 选择分类 -->
        <div class="share-category-box">
          <div class="share-category-title">
            <div class="share-category-left">
              <span
                >{{
                  getString(strings.Mind_Edit_Left_Menu_Select_Categories)
                }}：</span
              >
            </div>
            <div class="share-category-right">
              <div
                class="share-category-btn"
                v-for="(item, index) in diyCrispsListTags"
                :key="index"
                @click="getDiyCrispsListValue(index, item.id)"
              >
                <span
                  class="share-category-select"
                  :class="shareCategoryBtn == index ? 'add-share-category' : ''"
                  >{{ item.name }}</span
                >
                <img
                  src="../../../static/img/userData/share_pc_close.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="share-category-style">
            <div
              class="share-category-btn"
              v-for="(item, index) in crispsListTags.kinds"
              :key="index"
              @click="getCrispsListType(index)"
            >
              <span
                :class="item.select ? 'add-share-category' : ''"
                class="share-category-select"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <!-- <div
            class="share-category-type"
            v-if="crispsListTags.kinds != undefined"
          >
            <div
              class="share-category-btn"
              v-for="(item, index) in crispsListTags.kinds[shareCategoryBtn]
                .tags"
              :key="index"
              @click="getCrispsListType(index)"
              :class="item.select ? 'share-type-select' : ''"
            >
              <span>{{ item.name }}</span>
            </div>
          </div> -->
        </div>

        <!-- button -->
        <div class="share-potato-button">
          <!-- 自定义分类 -->
          <div class="share-comment-box" style="margin-top: 10px">
            <div class="share-comment-text">
              <span>{{
                getString(strings.Mind_Edit_Left_Menu_Custom_Category)
              }}</span>
            </div>
            <div class="share-comment-input">
              <a-form-item>
                <a-input
                  v-decorator="['diyTitle']"
                  :maxLength="6"
                  ref="diyTitle"
                  :placeholder="
                    getString(strings.Mind_Edit_Left_Menu_Edit_Add_Category)
                  "
                />
              </a-form-item>
            </div>
            <div class="share-comment-btn">
              <div type="primary" @click="changeAddTitle">
                <img
                  src="../../../static/img/userData/addClassification.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- 是否允许评论 -->
          <div class="share-comment-box" style="margin-top: 10px">
            <div class="share-comment-text">
              <span>{{
                getString(strings.Mind_Edit_Left_Menu_Allow_Comments)
              }}</span>
            </div>
            <div class="share-comment-switch">
              <a-switch
                size="small"
                :v-model="shareComment"
                default-checked
                @change="onChange"
              />
            </div>
          </div>
          <!-- 保存 -->
          <div class="share-comment-box" style="margin-top: 10px">
            <div class="share-comment-text">
              <span>{{
                getString(strings.Mind_Edit_Left_Menu_Allow_Save)
              }}</span>
            </div>
            <div class="share-comment-switch">
              <a-switch
                v-model="saveSwitch"
                size="small"
                @change="onChangeSave"
              />
            </div>
          </div>
          <!-- 赚取M豆 -->
          <div class="share-comment-box" style="margin-top: 10px">
            <div class="share-comment-text">
              <span>{{ getString(strings.Earn_Mbeans) }}</span>
              <span
                ><a
                  class="share-text-link"
                  href=""
                  @click.prevent="seeExplain"
                  >{{ $getStringObj.getString($Strings.Mind_Explain) }}</a
                >
              </span>
            </div>
            <div class="share-comment-switch">
              <div class="m-bean-box">
                <span class="m-bean-text"> M </span>
                <img
                  src="../../assets/img/user/member/mBean.svg"
                  alt=""
                  width="14px"
                  style="margin: 0 8px 0 4px"
                />
                <a-select
                  v-model="mBeanNumber"
                  style="width: 102px"
                  @change="mBeanHandleChange"
                  :disabled="!shareEarnMbeans"
                >
                  <a-select-option
                    v-for="item of mBeanList"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.value }}
                  </a-select-option>
                </a-select>
              </div>
              <a-switch
                size="small"
                v-model="shareEarnMbeans"
                @change="onEarnMbeansChange"
              />
            </div>
          </div>

          <div class="share-form-submit">
            <a-form-item class="user-submit">
              <a-button @click="colseSharePotatoChips">{{
                getString(strings.Global_Cancel)
              }}</a-button>
              <a-button type="primary" html-type="submit">
                {{ getString(strings.Global_Ok) }}
              </a-button>
            </a-form-item>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import HttpServerConfig from "../../common/HttpServerConfig";
import getString from "../../common/lang/language";
import strings from "../../common/lang/strings";
import {
  postCrispsListTags,
  postCrispsNewTag,
  postCrispsShare,
  postCrispsGmapToCrisps,
} from "../../common/netWork/crisps_api";
import sharePotatoChips from "../../assets/css/sharepotatochips/sharePotatoChips.less";

export default {
  data() {
    return {
      strings,
      crispsListTags: "",
      shareCategoryBtn: 0,
      shareCategoryType: true,
      shareForm: this.$form.createForm(this, { name: "coordinated" }),
      diyCrispsListTags: [], //自定义标签
      diyInputLength: "0",
      shareComment: true, //是否允许评论
      saveSwitch: false, //允许保存
      shareEarnMbeans: false, //是否赚取M豆
      mBeanNumber: 0, //使用时需要支付的M豆数量
      mBeanList: [
        {
          label: 1,
          value: 1,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      shareCategoryCover: (state) => state.sharePotatoChips.shareData,
    }),
  },
  created() {},
  mounted() {
    this.useShortcut(false);
    this.shareForm.setFieldsValue({
      title: this.shareCategoryCover.name,
      subtitle: this.shareCategoryCover.subtitle,
    });
    // console.log(this.shareCategoryCover.name);
    this.postCrispsListTags();
  },
  methods: {
    ...mapMutations(["sharePotatoChipsModel", "useShortcut"]),
    getString(i) {
      return getString(i);
    },
    //关闭分享
    colseSharePotatoChips() {
      this.sharePotatoChipsModel(false);
    },
    //完成分享
    handleSharePotatoChips(e) {
      e.preventDefault();
      let diyTitleDom = this.$refs.diyTitle;
      let diyTitleValue = "";
      if (diyTitleDom) {
        diyTitleValue = diyTitleDom.value;
      }
      if (diyTitleValue) {
        this.changeAddTitle();
      } else {
        this.shareForm.validateFields((errors, value) => {
          if (errors) {
            return;
          } else {
            if (value.title == "") {
              this.shareForm.setFieldsValue({
                title: this.shareCategoryCover.name,
              });
            }
            // let mindMapId;
            // if(this.$route.query.id) {
            //    mindMapId = this.$route.query.id;
            // } else if (this.$route.params.id) {
            //   mindMapId = this.$route.params.id;
            // }

            // let mindMapId = this.$route.query.id;
            let cover = this.shareCategoryCover.cover;
            let tags = this.diyCrispsListTags;
            let title = value.title;
            let subtitle = value.subtitle;
            let isPrivate = false;
            let commentable = this.shareComment;
            let isUsable = this.saveSwitch;
            let mbeansRequired = this.shareEarnMbeans ? this.mBeanNumber : 0;
            let Tag = this.diyCrispsListTags;

            // console.log('mindMapId',mindMapId);
            // console.log('cover',cover);
            // console.log('title',title);
            // console.log('subtitle',subtitle);
            // console.log('isPrivate',isPrivate);
            // console.log('commentable',commentable);
            // console.log('mbeansRequired',mbeansRequired);
            if (this.$router.history.current.name == "gmap") {
              //共享组的导图分享到风暴
              let gmapId = this.$route.query.id;
              postCrispsGmapToCrisps(
                {
                  gmapId,
                  cover,
                  tags,
                  title,
                  subtitle,
                  isPrivate,
                  commentable,
                  isUsable,
                  mbeansRequired,
                },
                (res) => {
                  this.colseSharePotatoChips();
                  this.$message.success(
                    getString(strings.Message_Tips_Share_Success)
                  );
                },
                (error) => {
                  this.$message.error(error.desc);
                },
                { "X-Type": "crisps-cover" }
              );
            } else {
              let mindMapId = this.$route.query.id;
              postCrispsShare(
                {
                  mindMapId,
                  cover,
                  tags,
                  title,
                  subtitle,
                  // isPrivate,
                  commentable,
                  isUsable,
                  mbeansRequired,
                },
                (res) => {
                  this.colseSharePotatoChips();
                  this.$message.success(
                    getString(strings.Message_Tips_Share_Success)
                  );
                },
                (error) => {
                  this.$message.error(error.desc);
                }
              );
            }
          }
        });
      }
    },
    //拉取推荐分类
    postCrispsListTags() {
      postCrispsListTags(
        null,
        (res) => {
          if (res) {
            if (res.maxSelection === undefined) {
              res.maxSelection = 3;
            }
            if (res.kinds && res.kinds.length > 0) {
              for (let j = 0; j < res.kinds.length; j++) {
                res.kinds[j].select = false;
              }
              res.kinds[0].select = true;
              let name = res.kinds[0].name;
              let id = res.kinds[0].id;
              this.diyCrispsListTags.push({ name, id }); //默认第一条数据
              //share-category-type
              res.kinds.forEach((element, index) => {
                if (index == 0) {
                  res.kinds[index].select = true;
                } else {
                  res.kinds[index].select = false;
                }
              });
              this.crispsListTags = res;
            }
            let mbeansOptions = res.mbeansOptions; //付费使用的M豆可选数量列表
            if (!!mbeansOptions && mbeansOptions.length > 0) {
              this.mBeanNumber = mbeansOptions[0];
              this.mBeanList = mbeansOptions.map((item) => {
                return {
                  label: item,
                  value: item,
                };
              });
            }
          }
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    //点击添加分类
    getCrispsListType(index) {
      this.shareCategoryBtn = index;
      let maxSelection = this.crispsListTags.maxSelection;
      let crispsListTags = this.diyCrispsListTags;
      let diyCrispsListTagsLen = crispsListTags.length;
      if (diyCrispsListTagsLen >= maxSelection) {
        this.$message.warning(
          getString(strings.Message_Tips_Max_Add_Class) +
            maxSelection +
            getString(strings.Message_Tips_Style)
        );
        return;
      }
      //点击的 id name style
      let crispsListTagsKinds = this.crispsListTags.kinds;
      if (crispsListTagsKinds && crispsListTagsKinds.length > 0) {
        let tag = crispsListTagsKinds[index];
        let id = tag.id;
        let name = tag.name;
        let crispsListBtnSelect = tag.select;
        tag.select = !crispsListBtnSelect;
        if (!crispsListBtnSelect) {
          for (let i = 0; i < diyCrispsListTagsLen; i++) {
            const diyId = crispsListTags[i].id;
            if (diyId == id) {
              return;
            }
          }
          crispsListTags.push({ name, id });
        } else {
          let newDiyCrispsListTags = crispsListTags.filter(
            (element) => element.id != id
          );
          this.diyCrispsListTags = newDiyCrispsListTags;
        }
      }
    },
    //删除分类
    getDiyCrispsListValue(index, id) {
      let crispsListTagsKinds = this.crispsListTags.kinds;
      let crispsListTagsKindsLen = crispsListTagsKinds.length;
      if (crispsListTagsKinds && crispsListTagsKindsLen > 0) {
        for (let i = 0; i < crispsListTagsKindsLen; i++) {
          let childId = crispsListTagsKinds[i].id;
          if (id == childId) {
            crispsListTagsKinds[i].select = false;
          }
        }
        this.diyCrispsListTags.splice(index, 1);
      }
    },
    //添加自定义分类
    changeAddTitle() {
      let diyTitleDom = this.$refs.diyTitle;
      if (diyTitleDom) {
        const value = diyTitleDom.value;
        if (value) {
          let maxSelection = this.crispsListTags.maxSelection;
          if (this.diyCrispsListTags.length >= maxSelection) {
            this.$message.warning(
              getString(strings.Message_Tips_Max_Add_Class) +
                maxSelection +
                getString(strings.Message_Tips_Style)
            );
            return;
          } else {
            postCrispsNewTag(
              { name: value },
              (res) => {
                this.diyCrispsListTags.push({ id: res.tagId, name: res.name });
                this.shareForm.setFieldsValue({
                  diyTitle: "",
                });
                this.$message.success(getString(strings.Added_Successfully));
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          }
        }
      }
    },
    onChange(checked) {
      this.shareComment = checked;
    },
    onChangeSave(checked) {
      // this.shareComment = checked;
    },
    onEarnMbeansChange(checked) {
      this.shareEarnMbeans = checked;
    },
    httpImageTo(s) {
      return HttpServerConfig.getMediaSrc(s);
    },
    mBeanHandleChange(value) {
      this.mBeanNumber = value;
      // console.log(this.mBeanNumber);
    },
    seeExplain() {
      //internationalization
      const h = this.$createElement;
      this.$info({
        title: this.$getStringObj.getString(this.$Strings.Mind_Explain),
        content: h("div", {}, [
          h(
            "p",
            this.$getStringObj.getString(this.$Strings.Share_Storm_Introduction)
          ),
          h(
            "p",
            this.$getStringObj.getString(this.$Strings.Share_Storm_Details)
          ),
        ]),
        centered: true,
        okText: this.$getStringObj.getString(this.$Strings.Global_Ok),
        onOk() {},
      });
    },
  },
  watch: {
    shareEarnMbeans(newVal) {
      if (newVal) {
        this.mBeanNumber = 1;
        this.saveSwitch = true;
      }
    },
    saveSwitch(newVal) {
      if (!newVal) {
        this.mBeanNumber = this.mBeanList[0].value;
        this.shareEarnMbeans = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
#share-potato-chips {
  max-width: 690px;
  width: 100%;
  // height: 622px;
  border-radius: 9px;
  overflow: hidden;
  background: #ffffff;
  position: absolute;
  z-index: 1000;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .share-pc-box {
    // margin: 30px;
    // min-height: 570px;
    position: relative;
    .share-potato-button {
      // position: absolute;
      // bottom: 20px;
      width: 100%;
      padding: 0px 30px 20px 30px;
    }
  }
  .share-title-box {
    height: 40px;
    padding: 0 30px;
    background: #efefef;
    .share-title-text {
      span {
        display: flex;
        // justify-content: center;
        align-items: center;
        font-size: 18px;
        font-family: Noto Sans SC;
        /*font-weight: bold;*/
        line-height: 40px;
        color: #333333;
        opacity: 1;
      }
    }
    .share-close-model {
      position: absolute;
      right: 30px;
      top: 9px;
      .close-button-img {
        width: 16px;
        cursor: pointer;
        opacity: 0.8;
      }
      .close-button-img:hover {
        opacity: 1;
      }
    }
  }

  .share-cover-text {
    padding: 20px 30px 0 30px;
    height: 140px;
    display: flex;
    .share-cover {
      width: 120px;
      height: 96px;
      background: #fffced;
      box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .share-text {
      width: 493px;
      margin-left: 10px;
      .share-text-division {
        border-bottom: 1px solid #dbdbdb;
        margin: 8px 0px;
      }
      .share-text-title {
        display: flex;
        width: 100%;
        height: 50px;
        position: relative;
        .ant-form-item {
          width: 100%;
        }
        textarea {
          width: 100%;
          height: 42px;
          border-radius: 2px;
          border: none;
          font-size: 18px;
          /*font-weight: bold;*/
          color: #666666;
          opacity: 1;
          display: flex;
          justify-content: center;
          padding: 9px 55px 0 10px;
          transition: 0.5s;
        }
        // input:focus {
        //   border: 1px solid #ff7c65;
        //   transition: 0.5s;
        // }
        .title-input-icon {
          position: absolute;
          right: 10px;
          z-index: 1000;
          top: 7px;
        }
      }
      .share-text-subtitle {
        display: flex;
        width: 100%;
        height: 45px;
        position: relative;
        .ant-form-item {
          width: 100%;
        }
        textarea {
          width: 100%;
          height: 62px;
          border-radius: 2px;
          border: none;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 9px 55px 0 10px;
        }
        // input:focus {
        //   border: 1px solid #ff7c65;
        //   transition: 0.5s;
        // }
        .subtitle-input-icon {
          position: absolute;
          right: 10px;
          z-index: 1000;
          top: 7px;
        }
      }
      .share-text-subtitle {
        display: flex;
      }
    }
  }

  .share-category-box {
    padding: 10px 30px 0 30px;
    .share-category-title {
      height: 28px;
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        font-family: Noto Sans SC;
        /*font-weight: bold;*/
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
      // span:last-child :first-child{
      //   margin-left: 16px;
      //   font-size: 12px;
      //   font-family: Noto Sans SC;
      //   font-weight: 400;
      //   line-height: 17px;
      //   color: #999999;
      //   opacity: 1;
      // }
      .share-category-right {
        display: flex;
        .share-category-btn {
          cursor: pointer;
          background: #ffe4df;
          padding: 3px 12px;
          border-radius: 4px;
          margin: 0 10px;
          position: relative;
          span {
            font-size: 16px;
            color: #ff765e;
            margin-left: 0;
            font-weight: 400;
          }
          img {
            position: absolute;
            right: -6px;
            top: -6px;
          }
        }
      }
    }
    /**标题Title */
    .share-category-style {
      display: flex;
      align-items: center;
      max-width: 620px;
      width: 100%;
      height: 60px;
      // border-bottom: 1px dashed #dbdbdb;
      overflow: hidden;
      overflow: auto;
      margin-top: 10px;
      .share-category-btn {
        cursor: pointer;
        font-size: 16px;
        color: #666666;
        border-radius: 4px;
        text-align: center;
        margin-right: 16px;
        min-width: 50px;
        // overflow: hidden;
        .share-category-select {
          background: #f1f1f1;
          padding: 5px 12px;
        }
      }
      .add-share-category {
        color: #ff765e;
        background: #ffe4df !important;
      }
    }
    //标题滚动条
    .share-category-style::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      /**/
    }
    .share-category-style::-webkit-scrollbar-track {
      background: rgb(239, 239, 239);
      border-radius: 2px;
    }
    .share-category-style::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 5px;
    }
    .share-category-style::-webkit-scrollbar-thumb:hover {
      background: #bfbfbf;
    }
    .share-category-type {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      overflow: auto;
      max-height: 100px;
      .share-category-btn {
        cursor: pointer;
        background: #f1f1f1;
        padding: 3px 12px;
        border-radius: 15px;
        margin: 10px;
        span {
          font-size: 16px;
          color: #666666;
        }
      }
      .share-type-select {
        background: #ffe4df;
        transition: 0.5s;
        span {
          color: #ff765e;
          transition: 0.5s;
        }
      }
    }
    .share-category-type::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    .share-category-type::-webkit-scrollbar-track {
      background: rgb(239, 239, 239);
      border-radius: 2px;
    }
    .share-category-type::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 5px;
    }
    .share-category-type::-webkit-scrollbar-thumb:hover {
      background: #bfbfbf;
    }
  }

  .share-comment-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    .share-comment-input {
      height: 40px;
      max-width: 526px;
      width: 100%;
      input {
        border: none;
        border-bottom: 1px solid #dbdbdb;
      }
      input:focus {
        border-bottom: 1px solid #a8a8a8;
        box-shadow: none;
      }
    }
    .share-comment-text {
      width: 100%;
      font-size: 16px;
      font-family: Noto Sans SC;
      line-height: 22px;
      color: #666666;
      opacity: 1;
      .share-text-link {
        font-size: 14px;
        color: #ec705a;
        text-decoration: underline;
        margin-left: 10px;
      }
    }
    .share-comment-switch {
      min-width: 180px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .m-bean-box {
        display: flex;
        align-items: center;
        margin-right: 8px;
        .m-bean-text {
          font-size: 14px;
          color: #333333;
        }
      }
    }
    .share-comment-btn {
      position: absolute;
      right: 30px;
      div {
        cursor: pointer;
      }
    }
  }

  .share-form-submit {
    .user-submit {
      display: flex;
      justify-content: center;
      margin-top: 38px;
      margin-bottom: 0;
      .ant-form-item-control-wrapper {
        .ant-form-item-control {
          .ant-form-item-children {
            display: flex;
          }
        }
      }
      button {
        display: inline-block;
        margin: 0px 20px;
        // width: 80px;
        height: 32px;
        border-radius: 4px;
      }
    }
  }
}
textarea {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
</style>