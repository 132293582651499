<template>
<!-- 图标 -->
  <div id="Complie-Right-Icon-box" class="ComplieRightMenuIcon-box" ref="complieRightMenuIconBox" :style="{background:complieRightMenuBg}">
    <div class="complie-rule-header-box" ref="rightMenuIconHeadBox">
      <div class="complie-rule-header">
        <div
          class="complie-rule-menu-box"
          v-for="(item, index) in menuBtn"
          :key="index"
          :class="{ active: click == index }"
          @click.stop="getComplieIcon(index)"
        >
          <div class="menu-text">{{ getString(item.titel) }}</div>
        </div>
      </div>
    </div>
    <div class="complie-rule-content-box">
      <div
        class="complie-rule-content"
        v-for="(item, index) in menuBtn"
        :key="index"
        :class="{ showComplieIconContent: index == MenuContent }"
      >
        <component :is="item.ComplieMenuIconCompoennt" />
      </div>
    </div>

  </div>
</template>

<script>
import ComplieRightMenuRule from "./ComplieChildRightMenu/ComplieRightMenuRule";
import ComplieRightMenu3D from "./ComplieChildRightMenu/ComplieRightMenu3D";
import ComplieRightMenuStudy from "./ComplieChildRightMenu/ComplieRightMenuStudy";
import ComplieRightMenuWork from "./ComplieChildRightMenu/ComplieRightMenuWork";
import ComplieRightMenuPerSon from "./ComplieChildRightMenu/ComplieRightMenuPerSon";
import ComplieRightMenuAniMal from "./ComplieChildRightMenu/ComplieRightMenuAniMal";
import ComplieRightMenuFood from "./ComplieChildRightMenu/ComplieRightMenuFood";
import strings from '../../../common/lang/strings';
import getString from '../../../common/lang/language';
import Colors from "../../../utils/Colors";

export default {
  data() {
    return {
      menuBtn: [
        {
          titel: strings.Mind_Edit_Right_Menu_routine,
          ComplieMenuIconCompoennt: "ComplieRightMenuRule",
        },
        {
          titel: strings.Mind_Edit_Right_Menu_3D,
          ComplieMenuIconCompoennt: "ComplieRightMenu3D",
        },
        {
          titel: strings.Mind_Edit_Right_Menu_Learning,
          ComplieMenuIconCompoennt: "ComplieRightMenuStudy",
        },
        {
          titel: strings.Mind_Edit_Right_Menu_Work,
          ComplieMenuIconCompoennt: "ComplieRightMenuWork",
        },
        {
          titel: strings.Mind_Edit_Right_Menu_Characters,
          ComplieMenuIconCompoennt: "ComplieRightMenuPerSon",
        },
        {
          titel: strings.Mind_Edit_Right_Menu_Animals,
          ComplieMenuIconCompoennt: "ComplieRightMenuAniMal",
        },
        {
          titel: strings.Mind_Edit_Right_Menu_Food,
          ComplieMenuIconCompoennt: "ComplieRightMenuFood",
        },
      ],
      click: 0,
      MenuContent: 0,
      strings,
      complieRightMenuBg:'transparent'
    };
  },
  components: {
    ComplieRightMenu3D,
    ComplieRightMenuRule,
    ComplieRightMenuStudy,
    ComplieRightMenuWork,
    ComplieRightMenuPerSon,
    ComplieRightMenuAniMal,
    ComplieRightMenuFood,
  },
  mounted(){
    this.setDardarkMode()
    let daGangModels = this.$store.state.showDaGangModels;//大纲
    if (daGangModels) {
      this.complieRightMenuBg = '#ffffff';
    }else{
      this.complieRightMenuBg = 'transparent';
    }
  },
  methods: {
    //语言包
    getString(i){
      return getString(i)
    },
    getComplieIcon(index) {
      this.click = index;
      this.MenuContent = index;
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.rightMenuIconHeadBox;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        let daGangModels = this.$store.state.showDaGangModels;//大纲
        if (boxDom) {
          if (dardarkMode.darkMode && !daGangModels) {
            boxDom.style.background = Colors.getUiColor(dardarkMode.mindBGColor);
            boxDom.style.borderTop = '1px solid rgba(255,255,255,.2)';
            boxDom.style.borderBottom = '1px solid rgba(255,255,255,.2)';
            document.documentElement.style.setProperty(
              "--rightMenuIconHoverBg",
              'rgba(255, 255, 255, 0.36)'
            );
          } else {
            boxDom.style.background = '#f0f0f0';
            boxDom.style.border = 0;
            document.documentElement.style.setProperty(
              "--rightMenuIconHoverBg",
              "#e0e0e0"
            );
          }
        }
        
      });
    },
  },
  watch: {
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
    "$store.state.showDaGangModels"(newshowDaGangModels) {
      //大纲
      if (newshowDaGangModels) {
        this.complieRightMenuBg = '#ffffff';
      }else{
        this.complieRightMenuBg = 'transparent';
      }
    },
  }  
};
</script>

<style scoped>
.ComplieRightMenuIcon-box {
  width: 291px;
  position: relative;
  cursor: auto;
  font-size: 12px;
  font-weight: 600;
}
.complie-rule-header-box {
  width: 291px;
  height: 32px; 
  background: #f0f0f0;
  position: fixed;
  z-index: 100;
}
.complie-rule-content-box {
  padding: 10px 15px;
  min-height: calc(100vh - 120px);
}
.complie-rule-header {
  width: 285px;
  display: flex;
}
.complie-rule-menu-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  transition: 0.3s;
  cursor: pointer;
}
.active {
  color: #FD492B;
  transition: 0.3s;
}
.complie-rule-content {
  display: none;
}
.showComplieIconContent {
  display: block !important;
}
</style>