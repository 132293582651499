<template>
<!-- 线条类型组件 -->
  <div class="menu-content-border-box">
    <div class="menu-use-content-inside">
      <ul class="menu-use-box">
        <li
          v-for="(item, index) in layoutStyleList"
          :key="index"
          @click="getNodeTypeAccout(item.nodeType)"
        >
          <img :src="item.layoutIcon" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import EncircleShapeType from '../../../viewmodel/datatype/EncircleShapeType';
export default {
  name: "ComplieChildMenuLayout",
  data() {
    return {
      layoutStyleList: [
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleLine/border_trapezoid.png"),
          nodeType: EncircleShapeType.LAYOUT_TRAPEZOID,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleLine/border_rectangle1.png"),
          nodeType: EncircleShapeType.LAYOUT_RECTANGLE_1,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleLine/border_rectangle2.png"),
          nodeType: EncircleShapeType.LAYOUT_RECTANGLE_2,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleLine/border_rectangle3.png"),
          nodeType: EncircleShapeType.LAYOUT_RECTANGLE_3,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/encircleLine/border_rectangle4.png"),
          nodeType: EncircleShapeType.LAYOUT_RECTANGLE_4,
        }

      ],
      layoutType: "",
      viewModel: null,
    };
  },
  created() {},
  mounted() {
    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
    }
  },
  methods: {
    ...mapMutations(["setEncircleLineType"]),
    getNodeTypeAccout(type) {
      this.setEncircleLineType(type)

      //将点击的值传送到页面。
      // this.bus.$emit("isShowMindMenu", false);
    },
  }
};
</script>
<style scoped>
.menu-use-content-inside {
  width: 268px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: relative;
  z-index: 9999;
}
.menu-use-content-inside ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
}
.menu-use-content-inside ul li {
  width: 45px;
  height: 45px;
  list-style: none;
  margin: 3px 8px;
  padding: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-use-content-inside ul li:hover {
  background: #e0e0e0;
}
.menu-use-content-inside ul li img {
  width: auto;
  height: auto;
}
.menu-theme-border {
  background: #e0e0e0;
}
</style>