import MindElementType from "../../../viewmodel/datatype/MindElementType";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType";

// 导图的布局结构列表,这个类里面在constructor定义数组要注意，因为外部会遍历这个类里面所有的数组。

class LayoutImg {
    constructor() {
        this.layoutStyleList = [
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/left_arrow.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/leftArrowWhite.svg"),
            nodeLayoutType: MindElementType.LAYOUT_LEFT_RIGHT,
            layoutType:NodeLayoutType.LAYOUT_LEFT_RIGHT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/right_arrow.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/right_arrow.svg"),
            nodeLayoutType: MindElementType.LAYOUT_RIGHT_LEFT,
            layoutType:NodeLayoutType.LAYOUT_RIGHT_LEFT,
          },
  
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/clockwise.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/clockwise.svg"),
            nodeLayoutType: MindElementType.LAYOUT_RIGHT_LEFT_CLOCKWISE,
            layoutType:NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE,
          },
          // {
          //   layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/left_right_layout_icon.png"),
          //   nodeLayoutType: MindElementType.LAYOUT_LEFT_RIGHT,
          // },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/left_layout_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/left_layout_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_LEFT,
            layoutType:NodeLayoutType.LAYOUT_LEFT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_right_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_right_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_RIGHT,
            layoutType:NodeLayoutType.LAYOUT_RIGHT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_bottom_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_bottom_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_BOTTOM,
            layoutType:NodeLayoutType.LAYOUT_BOTTOM,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_tree_left_right_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_tree_left_right_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_TREE_LEFT_RIGHT,
            layoutType:NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/tree_left_layout_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/tree_left_layout_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_TREE_LEFT,
            layoutType:NodeLayoutType.LAYOUT_TREE_LEFT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/tree_right_layout_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/tree_right_layout_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_TREE_RIGHT,
            layoutType:NodeLayoutType.LAYOUT_TREE_RIGHT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/brackets_left_layout_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/brackets_left_layout_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_BRACKETS_LEFT,
            layoutType:NodeLayoutType.LAYOUT_BRACKETS_LEFT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/brackets_right_layout_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/brackets_right_layout_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_BRACKETS_RIGHT,
            layoutType:NodeLayoutType.LAYOUT_BRACKETS_RIGHT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_form_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_form_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_FORM,
            layoutType:NodeLayoutType.LAYOUT_FORM,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_form_horizontal_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_form_horizontal_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_FORM_HORIZONTAL,
            layoutType:NodeLayoutType.LAYOUT_FORM_HORIZONTAL,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_triangle_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_triangle_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_TRIANGLE,
            layoutType:NodeLayoutType.LAYOUT_TRIANGLE,
          },
  
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_top.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_top.svg"),
            nodeLayoutType: MindElementType.LAYOUT_TOP,
            layoutType:NodeLayoutType.LAYOUT_TOP,
          },
  
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_top_tree_right.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_top_tree_right.svg"),
            nodeLayoutType: MindElementType.LAYOUT_TOP_TREE_RIGHT,
            layoutType:NodeLayoutType.LAYOUT_TOP_TREE_RIGHT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_top_tree_left.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_top_tree_left.svg"),
            nodeLayoutType: MindElementType.LAYOUT_TOP_TREE_LEFT,
            layoutType:NodeLayoutType.LAYOUT_TOP_TREE_LEFT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_top_tree_left_right.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_top_tree_left_right.svg"),
            nodeLayoutType: MindElementType.LAYOUT_TOP_TREE_LEFT_RIGHT,
            layoutType: NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_horizontal_right.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_horizontal_right.svg"),
            nodeLayoutType: MindElementType.LAYOUT_HORIZONTAL_RIGHT,
            layoutType: NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_vertical_right.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_vertical_right.svg"),
            nodeLayoutType: MindElementType.LAYOUT_VERTICAL_RIGHT,
            layoutType: NodeLayoutType.LAYOUT_VERTICAL_RIGHT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_fish_right.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_fish_right.svg"),
            nodeLayoutType: MindElementType.LAYOUT_FISH_RIGHT,
            layoutType: NodeLayoutType.LAYOUT_FISH_RIGHT,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/circle.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/circle.svg"),
            nodeLayoutType: MindElementType.LAYOUT_CIRCLE,
            layoutType: NodeLayoutType.LAYOUT_CIRCLE,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_free_tree_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_free_tree_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_FREE_TREE,
            layoutType:NodeLayoutType.LAYOUT_FREE_TREE,
          },
          // {
          //   layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/clockwise.svg"),
          //   nodeLayoutType: MindElementType.LAYOUT_RIGHT_LEFT_CLOCKWISE,
          //   // nodeLayoutType: MindElementType.LAYOUT_RIGHT_LEFT,
          // },
          // {
          //   layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/left_arrow.svg"),
          //   nodeLayoutType: MindElementType.LAYOUT_LEFT_RIGHT,
          // },
          // {
          //   layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/right_arrow.svg"),
          //   nodeLayoutType: MindElementType.LAYOUT_RIGHT_LEFT,
          // },
  
  
  
          // {
          //   layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/left_braces.svg"),
          //   nodeLayoutType: MindElementType.LAYOUT_TRIANGLE,
          // },
  
        ],
        this.layoutStyleStraight = [
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/layout_boubel.png"),
            nodeLayoutType: MindElementType.LAYOUT_BUBBLE,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_form_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_form_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_FORM,
          },
        ];
        this.layoutStyleLine = [
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_vertical_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_vertical_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_VERTICAL,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_horizontal_icon.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_horizontal_icon.svg"),
            nodeLayoutType: MindElementType.LAYOUT_HORIZONTAL,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_horizontal_s.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_horizontal_s.svg"),
            nodeLayoutType: MindElementType.LAYOUT_HORIZONTAL_S,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_ladder.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_ladder.svg"),
            nodeLayoutType: MindElementType.LAYOUT_LADDER,
          },
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_horizontal_crisscross.svg"),
            imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_horizontal_crisscross.svg"),
            nodeLayoutType: MindElementType.LAYOUT_HORIZONTAL_CRISSCROSS,
          }
  
        ];
        this.layoutStyleDoubelStraight = [
          {
            layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/layout_boubel.png"),
            nodeLayoutType: MindElementType.LAYOUT_BUBBLE,
          },
        ];
    }
}

export default LayoutImg