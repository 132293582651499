<template>
    <div @click="hidden()" class="group-created-new-group">
        <div @click.stop="hidden(true)" class="center-writer">
            <div @click="hidden()" class="close-modal">
                <a-icon type="close" />
            </div>
            <div  class="header-title">
                导图修改记录
            </div>
            <div id="group-mind-history-content-box" class="content-box">
                <div id="group-mind-history-content-box-child" class="conntent-box-child">
                    <div v-for="(item,index) in historyList" :key="index" class="each-list-box">
                        <div class="each-list-left-circlie">
                        </div>
                        <div class="each-list-right-content-box">
                            <img class="header-img"  :src="httpImageToPrefix(item.userAvatar)" alt="">
                            <div class="message-detail">
                                <div class="message-name">{{ item.username }}</div>
                                <div class="mind-content">
                                    <img class="mind-header-img"  src="../../../assets/img/user/plate_plan_4.jpg" v-real-img="httpImageToPrefix(item.cover)" >
                                    <div class="mind-detail">
                                        <div class="mind-title">
                                            {{ item.name }}
                                        </div>
                                        <div class="mind-sub-title">
                                            {{ item.subtitle }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mind-change-time">
                                    {{ timestampToTimeDHM(item.createTime) }}
                                    <template v-if="item.operation == 0">
                                        {{ item.username }}创建此文件
                                    </template>
                                    <template v-if="item.operation == 1">
                                        {{ item.username }}修改此文件
                                    </template>
                                    <template v-if="item.operation == 2">
                                        {{ item.username }}重命名此文件
                                    </template>
                                    <template v-if="item.operation == 3">
                                        {{ item.username }}移动此文件
                                    </template>
                                    <template v-if="item.operation == 4">
                                        {{ item.username }}删除此文件
                                    </template>
                                    <template v-if="item.operation == 5">
                                        {{ item.username }}恢复此文件
                                    </template>
                                    <template v-if="item.operation == 6">
                                        {{ item.username }}彻底删除此文件
                                    </template>
                                    <template v-if="item.operation == 7">
                                        {{ item.username }}清空回收站
                                    </template>
                                </div>
                            </div>                        
                        </div>

                    </div>
                </div>
            </div>

        </div>


    </div>
</template>

<script>
import { postGroupGmapRecords } from "../../../common/netWork/group_api";
import { mapMutations } from "vuex";

import httpImageToPrefix from '../../../utils/httpImageToPrefix';

import timestampToTime from "../../../utils/timestampToTime"
// import GroupTagCreated from "./GroupTagCreated";
// import GroupHeaderImageModal from "./groupCreatedChild/GroupHeaderImageModal"
export default {
    components: {

    },
    data() {
        return {
            historyList: [], //历史记录列表
            isScroll: true
        }
    },
    props: {
        showModal: {
            type: Boolean,
            defualt() {
                return false;
            },
        },
        initMindData: {
            type: Object,
            defualt() {
                return {};
            },
        }

    },
    created() {

    },
    methods: {
        ...mapMutations([
            "setRefreshMyGroupList",
        ]),

        scrollMoreData() {
            //滚动事件
            let scrollDom = document.getElementById("group-mind-history-content-box");
            let child = document.getElementById("group-mind-history-content-box-child");
            if( scrollDom == null || child == null) {
                return;
            }
            const scrollTopHeight = scrollDom.scrollTop ; //滚动高度
            const scrollHeight = scrollDom.clientHeight; //父盒子高度
            const childHeight = child.offsetHeight ; //网页可见区域高(包括边线的宽)
            if (
                scrollTopHeight + scrollHeight >= childHeight &&
                this.isScroll
            ) {
                this.isScroll = false;
                this.scrollPullList()
            }
        },

        scrollPullList() {
            this.pullGroupGfileRecord({gmapId : this.$route.query.id, lastId: this.historyList[this.historyList.length - 1].id}).then(res => {
                if (res != null && res.records != null) {
                    this.historyList = this.historyList.concat(res.records);
                }
            }).finally(res=> {
                this.isScroll = true;
            })
        },

        timestampToTimeDHM(timestamp) {
            return timestampToTime.formatTime(timestamp);
        },

        httpImageToPrefix(src) {    //拼接图片地址
           return httpImageToPrefix(src)
        },

        hidden(show) {
            if (show == true) {
                return;
            }
            let item = {
                show: false
            }
            this.$emit("hiddenModal", item);
        },

        pullGroupGfileRecord(obj) {
            return new Promise((resolve, reject) => {
                postGroupGmapRecords(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

    },
    watch: {
        showModal: {
            handler(newVal){
                if(newVal == true) {
                    // this.postGroupGfileRecords
                    // let gfileId = this.gfileId;
                    // let lastId = null;

                    // this.$route.params.id;
                    let obj = {
                        gmapId : this.$route.query.id,
                        lastId : ''
                    }
                    this.pullGroupGfileRecord(obj).then(res => {
                        this.historyList =  res.records;
                    })
                    this.$nextTick(() => {
                        let scrollDom = document.getElementById("group-mind-history-content-box");
                        if (scrollDom != undefined) {
                            scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
                            scrollDom.addEventListener("scroll", this.scrollMoreData, false); //添加绑定
                        }
                    });
                } else {
                    this.historyList = [];
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .group-created-new-group {
        position: fixed;
        left: 0; 
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        z-index: 10;
        .center-writer {
            width: 523px;
            min-height: 474px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 10px;
            .close-modal {
                position: absolute;
                right: 0;
                top: 0;
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
                font-size: 16px;
                color: #333;
                border-radius: 0 10px 0 0;
            }
            .close-modal:hover {
                background-color: #F2F2F2;
            }

            .header-title {
                text-align: center;
                color: #333;
                font-size: 16px;
                padding-top: 30px;
                padding-bottom: 10px;
            }

            .content-box {
                height: 383px;
                overflow-y: auto;
                .conntent-box-child {
                    .each-list-box {
                        margin: 0 20px;
                        padding-top: 16px;
                        border-left: 1px solid #d3d3d3;
                        position: relative;
                        .each-list-left-circlie {
                            position: absolute;
                            width: 9px;
                            height: 9px;
                            border-radius: 50%;
                            left: -0.5px;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            background-color: #d3d3d3;
                        }
                        .each-list-right-content-box {
                            margin-left: 20px;
                            display: flex;
                            border-bottom: 1px solid #ebebeb;
                            padding-bottom: 16px;
                            .header-img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                margin-right: 12px;
                            }
                            .message-detail {
                                .message-name {
                                    font-size: 16px;
                                    color: #333;
                                    margin-bottom: 6px;
                                    width: 268px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                                .mind-content{
                                    width: 268px;
                                    background-color: #F8F8F8;
                                    margin-bottom: 8px;
                                    display: flex;
                                    .mind-header-img {
                                        width: 46px;
                                        height: 46px;
                                        margin-right: 13px;
                                    }
                                    .mind-detail {
                                        .mind-title {
                                            color: #333;
                                            font-size: 14px;
                                            width: 200px;
                                            overflow: hidden;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                        }
                                        .mind-sub-title {
                                            color: #666;
                                            font-size: 12px;
                                            width: 200px;
                                            overflow: hidden;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                        }
                                    }
                                }
                                .mind-change-time {
                                    font-size: 14px;
                                    color: #999;
                                    width: 268px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }

                            }

                        }
                    }
                }
            }

        }

    }


</style>