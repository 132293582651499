<template>
  <!--  填充样式设置  -->
  <div class="ComplieHeaderImg" id="node-full-shape-panel" ref="nodeFullShapePanelBox">
    <div class="complie-header-imgbox">
      <!-- <ul class="menu-use-box"> -->
        <div
          class="complie-com-box"
          v-for="(item, index) in imgDataList"
          :key="index"
          :class=" index == imgDataList.length - 1 ? 'complie-com-box-last' : ''"
          @click="getCorrelationLineType(item.lineType, mapEditDarkMode?item.imgWhite:item.imgData)"
        >
          <div class="complie-left-icon">
            <img v-if="mapEditDarkMode" class="menu-line-icon" :src="item.imgWhite" />
            <img v-else class="menu-line-icon" :src="item.imgData" />
          </div>
        </div>
      <!-- </ul> -->
    </div>
  </div>
</template>
<script>
import { mapMutations,mapState } from "vuex";
import ConnectLineType from "../../../viewmodel/datatype/ConnectLineType";
import MindElementFullType from '../../../viewmodel/datatype/MindElementFullType';
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
export default {
  name: "ComplieChildMenuNodeFullShape",
  props: [
    "fullShapeType",
  ],
  data() {
    return {
      imgDataList: [
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundFull.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundFullWhite.svg"),
          lineType: MindElementFullType.FULL,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundDrawOblique.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundDrawObliqueWhite.svg"),
          lineType: MindElementFullType.DrawOblique,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundDrawHorizontal.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundDrawHorizontalWhite.svg"),
          lineType: MindElementFullType.DrawHorizontal,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundDrawGrid.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundDrawGridWhite.svg"),
          lineType: MindElementFullType.DrawGrid,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundGrid.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundGridWhite.svg"),
          lineType: MindElementFullType.Grid,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundGradient.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundGradientWhite.svg"),
          lineType: MindElementFullType.Gradient,
        },{
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/nodeBackgroundFull.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/nodeBackgroundFullWhite.svg"),
          lineType: MindElementFullType.GradientLR,
        },
      ],
      imgDataAccout: 0,
      layoutType: "",
      viewModel: null,
      mindOperateUIControllerView: null,
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => state.mapEditIsDardarkMode.darkMode,//深色模式
    }),
  },
  created() {},
  mounted() {
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations(["correlationNodeFullShape"]),
    getCorrelationLineType(type, url) {
      EditMindmapVM.changeNodeFullShape(type);
      this.correlationNodeFullShape(url);
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.nodeFullShapePanelBox;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (dardarkMode.darkMode) {
            boxDom.style.border = '1px solid rgba(255,255,255,.3)';
            boxDom.style.background = dardarkMode.bgColor;
          } else {
            boxDom.style.border = 0;
            boxDom.style.background = "#fff";
          }
        }
      });
    },
  },
  watch: {
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
    fullShapeType:{
    //   handler(newVal, oldVal) {
    //     if(newVal == null || newVal == "" || newVal == undefined){
    //       return;
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    },
  },
};
</script>
<style scoped>
.menu-use-content-inside {
  width: 268px;
  height: 184px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
}
.menu-use-content-inside ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.menu-use-content-inside ul li {
  width: 45px;
  height: 45px;
  float: left;
  list-style: none;
  margin: 8px 8px;
  padding: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-use-content-inside ul li:hover {
  background: #e0e0e0;
}
.menu-theme-line {
  background: #e0e0e0;
}
.ComplieHeaderImg {
  width: 198px;
  height: 324px;
  background: #ffffff;
  border: 1px solid #cccccc;
  opacity: 1;
  border-radius: 8px;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  margin-left: -70px;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
}
.complie-header-imgbox {
  margin: 7px 25px;
}
.complie-com-box {
  width: 138px;
  height: 44px;
  border-bottom: 1px dashed var(--exportMenuDashedColor);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.complie-com-box:hover{
  background-color: var(--exportMenuBg);
}
.complie-com-box-last {
  border-bottom: transparent;
}
.complie-left-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}
</style>