<template>
  <!-- 导图布局 -->
  <div class="menu-content-border-box" id="map-layout-box">
    <div class="menu-use-content-inside" ref="mapLayoutBox">
      <ul v-if="borderAccout.common" class="menu-use-box">
        <li
          v-for="(item, index) in layoutStyleList"
          :key="index"
          :class="
            selectedType == item.nodeLayoutType ? 'selected-layout-type-li' : ''
          "
          @click="
            getLayoutType(
              item.nodeLayoutType,
              mapEditDarkMode ? item.imgWhite : item.layoutIcon
            )
          "
        >
          <img v-if="mapEditDarkMode" :src="item.imgWhite" />
          <img v-else :src="item.layoutIcon" />
        </li>
      </ul>
      <ul v-if="borderAccout.Straight" class="menu-use-box">
        <li
          v-for="(item, index) in layoutStyleStraight"
          :key="index"
          @click="
            getLayoutType(
              item.nodeLayoutType,
              mapEditDarkMode ? item.imgWhite : item.layoutIcon
            )
          "
        >
          <img v-if="mapEditDarkMode" :src="item.imgWhite" />
          <img v-else :src="item.layoutIcon" />
        </li>
      </ul>
      <ul v-if="borderAccout.Curve" class="menu-use-box">
        <li
          v-for="(item, index) in layoutStyleLine"
          :key="index"
          @click="
            getLayoutType(
              item.nodeLayoutType,
              mapEditDarkMode ? item.imgWhite : item.layoutIcon
            )
          "
        >
          <img v-if="mapEditDarkMode" :src="item.imgWhite" />
          <img v-else :src="item.layoutIcon" />
        </li>
      </ul>
      <ul v-if="borderAccout.DoubelStraight" class="menu-use-box">
        <li
          v-for="(item, index) in layoutStyleDoubelStraight"
          :key="index"
          @click="
            getLayoutType(
              item.nodeLayoutType,
              mapEditDarkMode ? item.imgWhite : item.layoutIcon
            )
          "
        >
          <img v-if="mapEditDarkMode" :src="item.imgWhite" />
          <img v-else :src="item.layoutIcon" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import MindOperateUIControllerView from "../tools/MindOperateUIControllerView";
import MindType from "../../../viewmodel/datatype/MindType";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import IdGenerator from '../../../viewmodel/core/base/IdGenerator';

// globalLayout
export default {
  name: "ComplieChildMenuLayout",
  props: ["selectedLayoutType"],
  data() {
    return {
      layoutStyleList: [
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/left_arrow.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/leftArrowWhite.svg"),
          nodeLayoutType: MindElementType.LAYOUT_LEFT_RIGHT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/right_arrow.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/right_arrow.svg"),
          nodeLayoutType: MindElementType.LAYOUT_RIGHT_LEFT,
        },

        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/clockwise.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/clockwise.svg"),
          nodeLayoutType: MindElementType.LAYOUT_RIGHT_LEFT_CLOCKWISE,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/left_layout_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/left_layout_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_LEFT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_right_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_right_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_RIGHT,
        },

        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_bottom_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_bottom_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_BOTTOM,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_top.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_top.svg"),
          nodeLayoutType: MindElementType.LAYOUT_TOP,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/brackets_left_layout_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/brackets_left_layout_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_BRACKETS_LEFT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/brackets_right_layout_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/brackets_right_layout_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_BRACKETS_RIGHT,
        },

        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_tree_left_right_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_tree_left_right_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_TREE_LEFT_RIGHT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_top_tree_left_right.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_top_tree_left_right.svg"),
          nodeLayoutType: MindElementType.LAYOUT_TOP_TREE_LEFT_RIGHT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/tree_left_layout_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/tree_left_layout_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_TREE_LEFT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/tree_right_layout_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/tree_right_layout_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_TREE_RIGHT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_top_tree_left.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_top_tree_left.svg"),
          nodeLayoutType: MindElementType.LAYOUT_TOP_TREE_LEFT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_top_tree_right.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_top_tree_right.svg"),
          nodeLayoutType: MindElementType.LAYOUT_TOP_TREE_RIGHT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_horizontal_right.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_horizontal_right.svg"),
          nodeLayoutType: MindElementType.LAYOUT_HORIZONTAL_RIGHT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_vertical_right.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_vertical_right.svg"),
          nodeLayoutType: MindElementType.LAYOUT_VERTICAL_RIGHT,
        },

        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_fish_right.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_fish_right.svg"),
          nodeLayoutType: MindElementType.LAYOUT_FISH_RIGHT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_fish_left.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_fish_left.svg"),
          nodeLayoutType: MindElementType.LAYOUT_FISH_LEFT,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_form_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_form_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_FORM,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_form_horizontal_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_form_horizontal_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_FORM_HORIZONTAL,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_triangle_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_triangle_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_TRIANGLE,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/circle.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/circle.svg"),
          nodeLayoutType: MindElementType.LAYOUT_CIRCLE,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/radiate.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/radiate.svg"),
          nodeLayoutType: MindElementType.LAYOUT_RADIATE,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_free_tree_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_free_tree_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_FREE_TREE,
        },
      ],
      layoutStyleStraight: [
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/layout_boubel.png"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_boubel.svg"),
          nodeLayoutType: MindElementType.LAYOUT_BUBBLE,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_form_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_form_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_FORM,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/layout_form_horizontal_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_form_horizontal_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_FORM_HORIZONTAL,
        }
      ],
      layoutStyleLine: [
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_vertical_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_vertical_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_VERTICAL,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_horizontal_icon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_horizontal_icon.svg"),
          nodeLayoutType: MindElementType.LAYOUT_HORIZONTAL,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_horizontal_s.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_horizontal_s.svg"),
          nodeLayoutType: MindElementType.LAYOUT_HORIZONTAL_S,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_ladder.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_ladder.svg"),
          nodeLayoutType: MindElementType.LAYOUT_LADDER,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_horizontal_crisscross.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_horizontal_crisscross.svg"),
          nodeLayoutType: MindElementType.LAYOUT_HORIZONTAL_CRISSCROSS,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/lightModeIcon/layout_time_arc.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/time-layout/darkModeIcon/layout_time_arc.svg"),
          nodeLayoutType: MindElementType.LAYOUT_TIME_ARC,
        },
      ],
      layoutStyleDoubelStraight: [
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/layout_boubel.png"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/layout_boubel.svg"),
          nodeLayoutType: MindElementType.LAYOUT_BUBBLE,
        },
      ],
      layoutType: "",
      viewModel: null,
      mindOperateUIControllerView: null,
      borderAccout: {
        common: false,
        Straight: false,
        Curve: false,
        DoubelStraight: false,
      },
      selectedType: -1,
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => state.mapEditIsDardarkMode.darkMode, //深色模式
    }),
  },
  created() {},
  mounted() {
    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
      this.getLayoutAccout(this.$store.state.initData);
    }
    this.setDardarkMode();
    this.skeletonEntrance();
  },
  methods: {
    ...mapMutations([
      "nodeStyleLayout",
      "setShowBranchFreeLayout",
      "setBranchFreeLayout",
      "setShowSkeleton",
    ]),
    getLayoutType(type, url) {
      this.selectedType = type;
      if (type == MindElementType.SKELETON) {
        //显示骨架
        this.setShowSkeleton(true);
        return;
      }
      if (this.mindOperateUIControllerView == null) {
        this.mindOperateUIControllerView = new MindOperateUIControllerView(
          this.viewModel,
          this.viewModel
        );
      }
      this.mindOperateUIControllerView.onSelected(type);
      this.nodeStyleLayout(url);

      let isShowBranchFreeLayout = EditMindmapVM.isBranchNodeFreelayoutMode();
      let isBranchFreeLayout = EditMindmapVM.isBranchNodeFreelayout();
      this.setShowBranchFreeLayout(isShowBranchFreeLayout);
      this.setBranchFreeLayout(isBranchFreeLayout);
    },
    getLayoutAccout(newInitData) {
      let borderAccoutData = newInitData;
      if (borderAccoutData.mindType == MindType.BUBBLE_MAP) {
        this.borderAccout.Straight = true;
        this.borderAccout.common = false;
        this.borderAccout.Curve = false;
        this.borderAccout.DoubelStraight = false;
      } else if (borderAccoutData.mindType == MindType.LINE_MAP) {
        this.borderAccout.Straight = false;
        this.borderAccout.common = true;
        this.borderAccout.Curve = false;
        this.borderAccout.DoubelStraight = false;
      } else if (borderAccoutData.mindType == MindType.TIME_MAP) {
        this.borderAccout.Straight = false;
        this.borderAccout.common = false;
        this.borderAccout.Curve = true;
        this.borderAccout.DoubelStraight = false;
      } else if (borderAccoutData.mindType == MindType.DOUBLE_BUBBLE_MAP) {
        this.borderAccout.Straight = false;
        this.borderAccout.common = false;
        this.borderAccout.Curve = false;
        this.borderAccout.DoubelStraight = true;
      }
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.mapLayoutBox;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (dardarkMode.darkMode) {
            // boxDom.style.color = dardarkMode.fontColor;
            boxDom.style.background = dardarkMode.bgColor;
            boxDom.style.border = "1px solid rgba(255,255,255,.3)";
          } else {
            // boxDom.style.color = "#666666";
            boxDom.style.background = "#fff";
            boxDom.style.border = 0;
          }
        }
      });
    },
    skeletonEntrance() {
      let isShowSkeletonIcon = EditMindmapVM.isShowFrameworkSetting();
      // console.log(isShowSkeletonIcon);
      if (isShowSkeletonIcon) {
        this.layoutStyleList.unshift({
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/lightModeIcon/SkeletonIcon.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/mind_layout/darkModeIcon/SkeletonIcon.svg"),
          nodeLayoutType: MindElementType.SKELETON,
        });
      }
    },
  },
  watch: {
    selectedLayoutType: {
      handler(newVal, oldVal) {
        let nodeLayoutType = newVal.nodeLayoutType;
        if(nodeLayoutType){
          this.selectedType = nodeLayoutType;
        };
      },
      deep: true,
      immediate: true,
    },
    "$store.state.initData"(newInitData, olInitData) {
      this.getLayoutAccout(newInitData);
    },
    "$store.state.editMindmap"(newval, olval) {
      this.viewModel = newval;
    },
    //新手引导设置右布局
    "$store.state.setRightLayout"(newval, olval) {
      if (newval) {
        let item = this.layoutStyleList[8];
        this.getLayoutType(item.nodeLayoutType, item.layoutIcon);
      }
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.menu-use-content-inside {
  width: 268px;
  /* height: 190px; */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
}
.menu-use-content-inside ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.menu-use-content-inside ul li {
  width: 45px;
  height: 45px;
  float: left;
  list-style: none;
  margin: 8px 8px;
  padding: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.menu-use-content-inside ul li:hover {
  background: var(--rightClickMenuBg);
}
.selected-layout-type-li {
  border: 2px solid#8b8b8b;
  border-radius: 4px;
  /* background:#ff1f1f; */
}
.menu-use-content-inside ul li img {
  /* width: 80%;
  height: 80%; */
}
.menu-theme-border {
  background: #e0e0e0;
}
</style>