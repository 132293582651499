import {apiRequest} from './base'

//我的素材
export function postMaterialMyMaterials(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/my-materials',req,onOk,onFail,eventually,headers,onProgress)
}
//素材使用历史记录
export function postMaterialHistory(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/history',req,onOk,onFail,eventually,headers,onProgress)
}
//按标签查询素材
export function postMaterialQuery(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/query',req,onOk,onFail,eventually,headers,onProgress)
}
//搜索素材
export function postMaterialSearch(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/search',req,onOk,onFail,eventually,headers,onProgress)
}
//查看素材页面
export function postMaterialView(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/view',req,onOk,onFail,eventually,headers,onProgress)
}
//查看相似素材
export function postMaterialSimilar(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/similar',req,onOk,onFail,eventually,headers,onProgress)
}
//上传素材
export function postMaterialUpload(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/upload',req,onOk,onFail,eventually,headers,onProgress)
}
//使用素材
export function postMaterialUse(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/use',req,onOk,onFail,eventually,headers,onProgress)
}
//删除我的素材
export function postMaterialDelete(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/delete',req,onOk,onFail,eventually,headers,onProgress)
}
//查询风格
export function postMaterialQueryStyle(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/query-style',req,onOk,onFail,eventually,headers,onProgress)
}
//按类型查询风格
export function postMaterialQueryStyleByType(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/query-style-by-type',req,onOk,onFail,eventually,headers,onProgress)
}
//获取风格内容
export function postMaterialGetStyle(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/get-style',req,onOk,onFail,eventually,headers,onProgress)
}
//添加自定义风格
export function postMaterialAddMyStyle(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/add-my-style',req,onOk,onFail,eventually,headers,onProgress)
}
//删除自定义风格
export function postMaterialDeleteMyStyle(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/delete-my-style',req,onOk,onFail,eventually,headers,onProgress)
}
//收藏或取消收藏风格
export function postMaterialCollect(req,onOk,onFail,eventually,headers,onProgress){
    apiRequest('POST','/material/collect',req,onOk,onFail,eventually,headers,onProgress)
}
